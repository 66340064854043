<template>
	<div class="login-back">
		<div class="login-div">
			<div class="title-container">
			  <h3 class="title">更新密码</h3>
			</div>
			<el-form ref="form" :model="form" label-width="150px" >
				<el-form-item label="旧密码" prop="oldPwd" :rules="[{ required: true, message: '旧密码不能为空'}]">
				  <el-input type="password" v-model="form.oldPwd" autocomplete="off"></el-input>
				</el-form-item>
			  <el-form-item label="新密码" prop="pass" :rules="[{ required: true, message: '新密码不能为空'},{min: 8, max: 32, message: '长度在 8 到 32 个字符'},{pattern: /\D+/,message:'密码不能纯数字'},{pattern: /[^a-zA-Z]/,message:'密码不能纯字母'}]">
			    <el-input type="password" v-model="form.pass" autocomplete="off"></el-input>
			  </el-form-item>
			  <el-form-item label="确认新密码" prop="checkPass" :rules="[{validator:validatePwd,trigger: 'blur'}]">
			    <el-input type="password" v-model="form.checkPass" autocomplete="off"></el-input>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" @click="onSubmit">立即修改</el-button>
			  </el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	
export default {
  name: 'login',
  data () {
		let validatePwd = (rule, value, callback) => {
			console.log(rule,value,callback)
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.pass) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
    return {
			form:{
				oldPwd :'',
				pass :'',
				checkPass :'',
			},
			validatePwd
    }
  },
	methods:{
		async onSubmit(){
			if(await this.$refs['form'].validate()){
				let {username,password} = this.form;
				let state = '';
				try{
					const user = this.$auth.currentUser;
					await user.updatePassword(this.form.pass, this.form.oldPwd);
					this.$message({type: 'success',message: '密码更新成功!'});
					this.form = {};
				}catch(e){
					console.log(e.toString());
					this.$message.error('更新失败，请稍后重试。');
				}
			}
		}
	}
}
</script>

<style scoped>
.login-div {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
    background: #fff;
    box-shadow: 2px 2px 10px #9e9e9e;
    border-radius: 5px;
}
.login-back{
	box-sizing: border-box;
	background-image: url(../assets/login-back.png);
	padding: 5px;
	height: 100vh;
}
</style>
