<template>
	<div>
		<h-eard @setDate="date_=$event" :readOnly="true">
			<el-form-item>
				<el-select size="mini" v-if="!userInfo.spm&&!isAdmin"
					v-model="selSpm"
					style="width: 100px;"
					placeholder="选择绑定">
					<el-option v-for="item,i in spms" :key="i" :label="item.spm" :value="item._id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="!userInfo.spm&&!isAdmin">
				<el-button size="mini" @click="bindSpm()">{{'绑定'}}</el-button>
			</el-form-item>
			<el-form-item>
				<el-select size="mini" v-if="userInfo.spm||isAdmin"
					v-model="selSpms"
					multiple
					collapse-tags
					style="width: 150px;"
					placeholder="spm组长">
					<el-option v-for="item,i in outherSpms" :key="'_'+i" :label="item.spm" :value="item.spm"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-select style="width: 80px;" v-model="param.isJob" placeholder="请选择" clearable >
					<el-option label="在职" :value="true"></el-option>
					<el-option label="离职" :value="false"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="outherUsers.length>0">
				<el-popover placement="right" width="481" trigger="click">
				  <el-table :data="outherUsers" size="mini" border>
				    <el-table-column width="80" align="center" property="name" label="姓名"></el-table-column>
						<el-table-column prop="isJob" align="center" width="80" label="在职状态">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.isJob" size="small" type="success" >在职</el-tag>
								<el-tooltip v-else-if="isAdmin" effect="dark" :content="scope.row.outDate+'离职，点击删除'" placement="top">
									<el-button size="mini" @click="deleteUser(scope.row._id)">删除</el-button>
								</el-tooltip>
								<el-tag v-else size="small" type="info">已离职</el-tag>
							</template>
						</el-table-column>
				    <el-table-column prop="pName" align="center" width="100" label="p账号"> </el-table-column>
						<el-table-column prop="joinDate" align="center" width="120" label="入职时间"> </el-table-column>
						<el-table-column align="center"  width="100" label="操作">
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" content="将该员工认领为自己的组员。" placement="left">
									<el-button @click="addUser(scope.row._id)" type="primary" round plain size="mini">添加</el-button>
								</el-tooltip>
							</template>
						</el-table-column>
				  </el-table>
					<el-tooltip slot="reference" class="item" effect="dark" content="组长认领未被分配到组长的员工" placement="bottom">
						<el-button size="mini" >添加组员</el-button>
					</el-tooltip>
				</el-popover>
			</el-form-item>
			<el-form-item>
				<el-button size="mini" @click="dialogInfoFormVisible=true;copyObj(infoForm,{spm:userInfo.spm,isJob:true,zdyPb:{}})">新员工入职</el-button>
			</el-form-item>
			<el-form-item>
				<el-tooltip effect="dark" content="修改后的排班无法被安灯排班覆盖, 请谨慎操作" placement="bottom">
					<el-button type="danger" plain size="mini" @click="dialogPbsFormVisible=true;pbsForm=[];">批量排班调整</el-button>
				</el-tooltip>
			</el-form-item>
			<el-form-item v-if="isAdmin">
				<el-upload action="/" :show-file-list="false" :auto-upload="false" :on-change="importJb">
					<el-button size="mini" type="primary">导入加班</el-button>
				</el-upload>
			</el-form-item>
		</h-eard>
		<div class="content">
			<el-table :data="datas" size="mini" border style="width: 100%">
				<el-table-column type="index" align="center" width="45"> </el-table-column>
				<el-table-column prop="name" align="center" width="80" label="姓名"></el-table-column>
				<el-table-column prop="phone" align="center" width="100" label="电话"></el-table-column>
				<el-table-column prop="isJob" align="center" width="80" label="在职状态">
					<template slot-scope="scope">
						<el-tooltip effect="dark" :content="`${scope.row.joinDate}${scope.row.outDate?'至'+scope.row.outDate:'入职'}`" placement="top">
							<el-tag size="small" type="success" v-if="scope.row.isJob">在职</el-tag>
							<el-tag size="small" type="info" v-else-if="new Date()>new Date(scope.row.outDate+' 23:59:59')">已离职</el-tag>
							<el-tag size="small" v-else >离职中</el-tag>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="pName" align="center" width="120" label="p账号" ></el-table-column>
				<el-table-column prop="isAdon" align="center" width="50" label="安灯" >
					<template slot-scope="scope">
						<el-tooltip v-if="scope.row.isAdon" effect="dark" :content="scope.row.isAdon" placement="top">
							<i class="el-icon-success" style="font-size: 20px;color: #03a9f4;"></i>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="product" align="center" width="100" label="负责产品"></el-table-column>
				<el-table-column prop="zcqNum" align="center" width="80" label="出勤">
					<template slot-scope="scope">
						<el-tooltip effect="dark" placement="top" :content="`${'加班：'+scope.row.jbNum+'天'},${'请假：'+scope.row.qjNum+'天'}，${'夜班：'+scope.row.ybDays+'天'}`">
							<el-tag size="small">{{Math.round(scope.row.zcqNum*10)/10+'天'}}</el-tag>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="sum" align="center" width="70" label="缺勤">
					<template slot-scope="scope">
						<el-tag size="small" type="danger" v-if="scope.row.sum<0">{{Math.round(scope.row.sum*-10)/10}}天</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="scoreMentor" align="center" width="120" label="绩效导师"></el-table-column>
				<el-table-column prop="spm" align="center" width="80" label="组长"></el-table-column>
				<el-table-column fixed="right" min-width="310" align="left" label="操作">
					<template slot-scope="scope" v-if="isAdmin || scope.row.spm == userInfo.spm">
						<el-link @click="dialogPbListVisible=true;copyObj(pbData,scope.row)" type="primary" :underline="false"><i class="el-icon-date"></i>排班管理</el-link>
						<el-link style="margin-left: 10px;" @click="dialogInfoFormVisible=true;copyObj(infoForm,{...scope.row,zdyPb:(scope.row.zdyPb||{})})" type="success"  :underline="false"><i class="el-icon-info"></i>信息管理</el-link>
						<!-- <el-link style="margin-left: 10px;" @click="dialogPbFormVisible=true;copyObj(pbForm,{...scope.row,datas:[]})" type="warning" :underline="false"><i class="el-icon-edit"></i>调整排班</el-link> -->
					</template>
				</el-table-column>
			</el-table>
		</div>
		
		<el-dialog :title="`【${pbData.name}】的排班信息(单击日期调整班次)`" :close-on-click-modal="false" width="700px" :visible.sync="dialogPbListVisible">
			<el-calendar class="pb-list-calendar" style="margin-top: -30px;margin-bottom: -30px;">
				<template
					slot="dateCell"
					slot-scope="{date, data}">
					<el-tooltip effect="dark" :content="code_time[pbData.pbData[data.day]?pbData.pbData[data.day].replace(/\*/g,''):'']" placement="top">
						<div class="pb-list-cell" @click="dialogPbFormVisible=true;copyObj(pbForm,{...pbData,datas:[{date:data.day,mode:''}]});">
							<p class="cell-date">{{date.format('DD')}}</p>
							<p v-if="pbData.pbData[data.day]" :style="getStyle(pbData,data.day)">{{pbData.pbData[data.day].replace(/\*/g,'')}} <span v-if="pbData.visaFree&&pbData.visaFree[data.day]" style="color: #009688;font-size: 12px;">(免签)</span></p>
						</div>
					</el-tooltip>
				</template>
			</el-calendar>
		</el-dialog>
		
		<el-dialog :title="`${pbForm.name}排班调整`" :close-on-click-modal="false" width="800px" :visible.sync="dialogPbFormVisible">
			<el-form v-for="form,index in pbForm.datas" :ref="'pbForm'+index" :key="index" :inline="true" :model="form" size="mini">
				<el-form-item prop="date" label="调整日期" :rules="[{ required: true, message: '调整日期不能为空'}]">
					<el-date-picker style="width: 140px;" v-model="form.date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item prop="mode" label="调整类型" :rules="[{ required: true, message: '调整类型不能为空'}]">
					<el-select style="width: 130px;" v-model="form.mode" placeholder="请选择调整班次" filterable >
						<el-option label="清理班次" value="清理"></el-option>
						<el-option label="请假/调休" value="事"></el-option>
						<!-- <el-option label="加班" value="加班"></el-option> -->
						<el-option v-if="isAdmin" label="不打卡" value="不打卡"></el-option>
						<template v-for="item in codes">
							<el-option v-if="item.name!='不打卡'&&item.name!='事'" :key="item.name" :value="item.name">
								<el-tooltip effect="dark" :content="item.dateStr" placement="right">
									<span style="display: block;">{{item.name}}</span>
								</el-tooltip>
							</el-option>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item prop="overtime" label="强制设定加班班次" v-if="form.date&&form.mode=='加班'&&pbForm.pbData[form.date].indexOf('休')>-1" :rules="[{ required: true, message: '加班日期班次为休，请修改为正常班次。'}]" >
					<el-select style="width: 130px;" v-model="form.overtime" placeholder="请选择加班班次" filterable >
						<template v-for="item in codes">
							<el-option v-if="item.name!='不打卡'&&item.name!='事'&&item.name!='休'" :key="item.name" :value="item.name">
								<el-tooltip effect="dark" :content="item.dateStr" placement="right">
									<span style="display: block;">{{item.name}}</span>
								</el-tooltip>
							</el-option>
						</template>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="* 所选日期当前班次" v-if="form.mode!='加班' || pbForm.pbData[form.date].indexOf('休')==-1" >
					<el-input style="width: 130px;" :value="pbForm.pbData[form.date]&&pbForm.pbData[form.date].replace(/\*/g,'')" readonly ></el-input>
				</el-form-item> -->
				<el-form-item label="">
					<el-button type="danger" icon="el-icon-minus" @click="pbForm.datas.splice(index,1)" circle ></el-button>
				</el-form-item>
			</el-form>
			<el-button size="mini" @click="pbForm.datas.push({date:'',mode:'事',overtime:''})" icon="el-icon-circle-plus-outline" style="width: 100%;text-align: center;">增加</el-button>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogPbFormVisible = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="savePbChange">提 交</el-button>
			</div>
		</el-dialog>
		<el-dialog title="批量排班调整" width="1020px" :close-on-click-modal="false" :visible.sync="dialogPbsFormVisible">
			<el-form v-for="form,index in pbsForm" :ref="'pbsForm'+index" :key="index" :inline="true" :model="form" size="mini">
				<el-form-item prop="_ids" label="调整员工" :rules="[{ required: true, message: '调整员工不能为空'}]">
					<el-select v-model="form._ids" placeholder="请选择调整员工" filterable multiple collapse-tags style="width: 160px;" >
						<el-option  v-if="isAdmin" label="选择所有" value="all"> </el-option>
						<template v-for="item,i in dbDatas">
							<el-option  v-if="isAdmin||item.spm==userInfo.spm" :key="i" :label="item.name" :value="item._id"> </el-option>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item prop="dates" label="调整日期" :rules="[{ required: true, message: '调整日期不能为空'}]">
					<el-date-picker v-model="form.dates" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 230px;"></el-date-picker>
				</el-form-item>
				<el-form-item prop="rest" :label="form._ids[0]=='all'?'跳过班次休':'周末排休'">
					<el-switch v-model="form.rest"> </el-switch>
				</el-form-item>
				<el-form-item prop="value" label="调整班次" :rules="[{ required: true, message: '调整班次不能为空'}]">
					<el-select v-model="form.value" placeholder="请选择调整班次" filterable style="width: 120px;" >
						<el-option label="清理班次" value="清理"></el-option>
						<el-option label="请假/调休" value="事"></el-option>
						<el-option v-if="isAdmin" label="不打卡" value="不打卡"></el-option>
						<el-option v-if="isAdmin" label="加班" value="加班"></el-option>
						<template v-for="item in codes">
							<el-option v-if="item.name!='不打卡'&&item.name!='事'" :key="item.name" :value="item.name">
								<el-tooltip effect="dark" :content="item.dateStr" placement="right">
									<span style="display: block;">{{item.name}}</span>
								</el-tooltip>
							</el-option>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item label="">
					<el-button type="danger" icon="el-icon-minus" @click="pbsForm.splice(index,1)" circle ></el-button>
				</el-form-item>
			</el-form>
			<el-button size="mini" @click="pbsForm.push({_ids:[],dates:[],value:'',rest:false})" icon="el-icon-circle-plus-outline" style="width: 100%;text-align: center;">增加</el-button>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogPbsFormVisible = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="savePbsChange">提 交</el-button>
			</div>
		</el-dialog>
		<el-dialog width="800px" :title="infoForm._id?`【${infoForm.name}】信息调整`:'新员工入职'" :close-on-click-modal="false" :visible.sync="dialogInfoFormVisible">
			<el-form ref="infoForm" :model="infoForm" size="mini" label-width="100px">
				<el-row>
					<el-col :span="8">
						<el-form-item prop="name" label="姓名" :rules="[{ required: true, message: '必填'}]">
							<el-input v-model="infoForm.name" ></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="phone" label="电话" :rules="[{ required: true, message: '必填'}]">
							<el-input v-model="infoForm.phone"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="spm" label="组长" :rules="[{ required: true, message: '必填'}]">
							<el-select size="mini"
								v-model="infoForm.spm"
								style="width: 100%;"
								placeholder="选择组长">
								<el-option label="特殊(不归属组)" value="特殊"></el-option>
								<el-option v-for="item,i in outherSpms" :key="'-'+i" :label="item.spm" :value="item.spm"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="工号">
							<el-input v-model="infoForm.userNo" placeholder="工号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部门">
							<el-select v-model="infoForm.department" filterable allow-create placeholder="选择或输入">
								<el-option v-for="name in departments" :key="name" :label="name" :value="name"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="岗位">
							<el-select v-model="infoForm.post" filterable allow-create placeholder="选择或输入">
								<el-option v-for="name in posts" :key="name" :label="name" :value="name"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="导师">
							<el-select v-model="infoForm.mentor" filterable allow-create placeholder="选择或输入">
								<el-option v-for="name in mentors" :key="name" :label="name" :value="name"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="导师组织">
							<el-select v-model="infoForm.mentorOrg" filterable allow-create placeholder="选择或输入">
								<el-option v-for="name in mentorsOrgs" :key="name" :label="name" :value="name"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="绩效导师">
							<el-input v-model="infoForm.scoreMentor" @input="getInput($event)" placeholder="导师英文名(导师姓名)"></el-input>
							<!-- <el-select v-model="infoForm.scoreMentor" filterable allow-create placeholder="选择或输入">
								<el-option v-for="name in mentors" :key="name" :label="name" :value="name"> </el-option>
							</el-select> -->
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="办公地点">
							<el-select v-model="infoForm.workLocation" filterable allow-create placeholder="选择或输入">
								<el-option v-for="name in workLocations" :key="name" :label="name" :value="name"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="办公邮箱">
							<el-input v-model="infoForm.workEmail" placeholder="办公邮箱"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="办公微信">
							<el-input v-model="infoForm.workWx" placeholder="办公微信"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="负责产品" prop="product" :rules="[{ required: true, message: '必填'}]">
							<el-select v-model="infoForm.product" filterable allow-create default-first-option placeholder="请选择或输入负责产品">
								<el-option v-for="item in cpList" :key="item" :label="item" :value="item"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="入职日期" prop="joinDate" :rules="[{ required: true, message: '必填'}]">
							<el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="infoForm.joinDate" style="width: 100%;"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="在职状态">
							<el-radio-group v-model="infoForm.isJob">
								<el-radio :label="true">正常在职</el-radio>
								<el-radio :label="false">已离职/离职中</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="离职日期" prop="outDate" :rules="[{ required: true, message: '必填'}]" v-if="!infoForm.isJob">
					<el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="infoForm.outDate" style="width: 100%;"></el-date-picker>
				</el-form-item>
				<el-row v-if="infoForm._id">
					<el-col :span="8">
						<el-form-item prop="isAdon" label="p账号" :rules="[{ required: infoForm.isAdon, message: '安灯开始排班时间不为空则必填'}]">
							<el-input v-model="infoForm.pName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="16">
						<el-form-item label="安灯排班">
							<el-col :span="11">
								<el-date-picker type="date" placeholder="开始日期(无排班留空)" value-format="yyyy-MM-dd" v-model="infoForm.isAdon" style="width: 100%;"></el-date-picker>
							</el-col>
							<el-col class="line" :span="2" style="text-align: center;">至</el-col>
							<el-col :span="11">
								<el-date-picker type="date" placeholder="结束日期(不确定留空)" value-format="yyyy-MM-dd" v-model="infoForm.andonDateOut" style="width: 100%;"></el-date-picker>
							</el-col>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="固定班次" >
							<el-select v-model="infoForm.zdyPb.value" placeholder="不固定可不选">
								<el-option label="不固定班次" value=""></el-option>
								<el-option v-if="isAdmin" label="不打卡" value="不打卡"></el-option>
								<template v-for="item in codes">
									<el-option v-if="item.name!='不打卡'&&item.name!='事'" :key="item.name" :value="item.name">
										<el-tooltip effect="dark" :content="item.dateStr" placement="right">
											<span style="display: block;">{{item.name}}</span>
										</el-tooltip>
									</el-option>
								</template>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="16">
						<el-form-item label="固定日期">
							<el-col :span="11">
								<el-date-picker type="date" placeholder="开始日期(不固定留空)" value-format="yyyy-MM-dd" v-model="infoForm.zdyPb.start" style="width: 100%;"></el-date-picker>
							</el-col>
							<el-col class="line" :span="2" style="text-align: center;">至</el-col>
							<el-col :span="11">
								<el-date-picker type="date" placeholder="结束日期(不确定留空)" value-format="yyyy-MM-dd" v-model="infoForm.zdyPb.end" style="width: 100%;"></el-date-picker>
							</el-col>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogInfoFormVisible = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="saveInfoChange">提 交</el-button>
			</div>
		</el-dialog>
		
		<el-dialog title="加班数据导入确认" :close-on-click-modal="false" :visible.sync="jbSaveData">
			<el-form size="mini" label-width="100px" style="max-height: 300px;overflow: auto;">
				<el-form-item v-for="obj,key in jbSaveData" :key="key" :label="obj.name" style="margin-bottom: 3px;">
					<el-tag size="mini" style="margin-right: 5px;" v-for="val,date in obj.isOvertime" :key="date">{{date}}</el-tag>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="jbSaveData = null">取 消</el-button>
				<el-button size="mini" type="primary" @click="updatePbData(jbSaveData);jbSaveData = null">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Heard from '../components/heard.vue'
import XLSX from 'xlsx'
export default {
	name: 'count',
	components:{'h-eard':Heard},
	data(){
		return {
			param:{
				isJob:true,//默认在职
			},//
			isAdmin:false,//是否管理员
			cpList:['音视频-终端','音视频-云端','音视频-云通','即时通信IM','云服务器','数据库','大数据AI','中间件SCF','直播','加速','安全','计费','网络','计算','存储','容器TKE','其他'],
			codes:[],
			date_:[],//查询日期范围
			departments:["计算与安全组","网络组","通信与应用组","数据支持组","音视频云端组","音视频终端组","产研一组","产研二组"],//部门
			posts:['运维工程师','开发支持工程师','部门助理','前端开发工程师','数据分析师'],//
			mentors:['singleli(李沈)','adamxhe(何翔)','brinkmai(麦世勇)','alanan(安轲)','rickyu(于航)','bryanpgao(高鹏)','rupertzhang(张启荣)','joeexu(许彦昭)','clairehou(侯超)','waywang(汪华)','imandyhuang(黄文婷)','guoyanliang(梁国衍)','yuyuedeng(邓逾越)','adolphhuang(黄玉斌)','changlinli(黎常林)','jackwlchen(陈文龙)','ailanzhou(周朋伟)','lvanwu(吴煜林)','alexzmzhang(张敏)','joecao(曹祥文)','stevenshan(单少文)','runmouzhou(邹润谋)','walklu(陆佳青)'],//
			mentorsOrgs:['平台与架构服务一组','网络技术运营一组','基础技术服务一组','区域一组','基础技术服务二组','平台与架构服务二组','产品二组','平台与架构服务三组','区域三组','平台与架构服务四组','平台与架构服务五组','平台与架构服务七组','平台与架构服务八组','平台与架构服务九组','计算IAAS技术服务组'],//
			workLocations:['松日4F','第三极大厦11F','国人通信大厦6F','北京长远天地7F','松日16F','松日14F','武汉'],//
			spms:[],
			outherSpms:[],
			selSpm:'',
			selSpms:[],
			dbDatas:[],
			normWork:[],
			userInfo:{},//当前登录用户信息
			qjDate:new Date(),//请假日期
			dialogPbFormVisible:false,//排班调整弹出组件是否显示
			pbForm:{_id:'',name:'',sum:0,pbData:{},datas:[]},//排班调整表单
			dialogInfoFormVisible:false,//排班调整弹出组件是否显示
			infoForm:{_id:'',name:'',spm:'',phone:'',pName:'',isAdon:'',andonDateOut:'',isJob:'',userNo:'',department:'',post:'',joinDate:'',outDate:'',zdyPb:{},mentor:'',mentorOrg:'',scoreMentor:'',workLocation:'',workEmail:'',workWx:'',product:''},//组员信息调整表单
			dialogPbsFormVisible:false,
			pbsForm:[],//批量修改排班数据
			dialogPbListVisible:false,//组员排班数据是否显示
			pbData:{_id:'',name:'',pbData:{},isOvertime:{},visaFree:{}},//组员排班数据
			jbSaveData:null,//加班导入确认数据
		}
	},
	async created() {
		this.userInfo = await this.$getUserInfo();
		if(this.userInfo.roles[0] == '导师'){this.$router.push('/grade'); return;}
		this.isAdmin = this.userInfo.roles.includes('考勤管理员');
		this.userInfo.spm&&this.selSpms.push(this.userInfo.spm);
		let load = this.$loading();
		this.$db = this.$tcb.database();
		
		this.codes = await this.$db.collection('cms-gyy-pbcode').limit(100).orderBy('name','asc').get().then(res=>res.data);
		await this.getPbDate();
		
		load.close();
	},
	computed:{
		sDateList(){//实时计算的查询日期数组
			let date_ = this.date_, list = [];
			if(date_[0] && date_[1]){
				let nowDate = new Date(date_[0]);
				while(nowDate <= date_[1]){//对日期进行循环
					list.push(nowDate.format());
					nowDate.setDate(nowDate.getDate()+1);//加一天
				}
			}
			return list;
		},
		code_time(){//班次时间对照表
			let obj = {'':''};
			this.codes.map(v=>{obj[v.name] = v.dateStr});
			return obj;
		},
		datas(){//实时计算的数据
			let list = [];
			for(let index in this.dbDatas) {
				let user = this.dbDatas[index];
				if((!user.spm && !this.isAdmin) || (this.selSpms.length!=0 && !this.selSpms.includes(user.spm))) continue;
				if(this.param.isJob!==""){
					if(this.param.isJob!=user.isJob) continue;
				}
				let obj = {
					...user,
					index:index-0+1,
					noun:user.noun||0,
					reason:user.reason||[],
					sum:(user.noun||0),//修正值，默认0
					isOvertime:user.isOvertime||{},//加班日期对象
					visaFree:user.visaFree||{},
					night:0,
					ybDays:0,//夜班总天数
					ycqNum:0,//应出勤
					zcqNum:0,//总出勤
					jbNum:0,//加班
					qjNum:0,//请假
				};
				for (let ymd of this.sDateList) {
					let val = user.pbData[ymd];
					if(val){//存在值才进行写值和判断
						val = val.replace(/\*/g,'');//去除*号
						let normVal = this.normWork[ymd].replace(/\*/g,'');
						let ym = ymd.substr(0,7);
						let t = 1;
						if(val.indexOf('夜') == 0){
							if(!obj[ym+'ybDays']) obj[ym+'ybDays'] = 1;
							else obj[ym+'ybDays'] += 1;
							obj.ybDays += 1;
							t = 1.25;
							obj.night++;
							obj.noun += 0.25;
						}
						if(val == '事') obj.qjNum++;
						if((user.isOvertime||{})[ymd]){
							obj.jbNum++;
							t = 0;//加班不算出勤
						}
						let cqObj = {//出勤判断
							cq:(val!='休'?t:0),//出勤
							zj:(normVal!='休'?1:0),//标准
							cz:(normVal!='休'?(val!='休'?(t-1):-1):(val!='休'?t:0))
						}
						for(let key in cqObj){
							obj[ym+key] = obj[ym+key]?(obj[ym+key]+cqObj[key]):cqObj[key];
							if(key == 'cq')obj.zcqNum += cqObj[key];//总实际出勤
							if(key == 'cz')obj.sum += cqObj[key];//计算总计差值
							if(key == 'zj')obj.ycqNum += cqObj[key];//总计出勤天数（计薪天数）
						}
					}
				}
				list.push(obj);
			}
			
			list.sort((a,b)=>{
				if(this.isNightMode){//夜班统计模式
					return b.ybDays - a.ybDays;
				}else{
					return a.sum - b.sum;
				}
			});
			list.map((e,i)=>{e.listNum = i+1;});
			return list;
		},
		phoneObj(){
			let obj = {};
			this.dbDatas.map(user=>{
				obj[user.phone] = user._id;
			});
			return obj;
		},
		outherUsers(){
			let list = [];
			for(let user of this.dbDatas) {
				if(!user.spm && user.isJob)list.push(user);
			}
			return list;
		}
	},
	watch: {
		'infoForm.isJob':function(n){
			if(n) this.infoForm.outDate = '';
		}
	},
	methods: {
		getInput(e){
			this.infoForm.scoreMentor = e.replace(/ /g,'').replace('（','(').replace('）',')');
		},
		async getPbDate(){
			let user = await this.$db.collection('cms-gyy-user').limit(1000).get();
			this.dbDatas = user.data;
			let spms=[],outherSpms=[];
			for (let u of user.data) {
				if(u.spm == u.name && !u.userid) spms.push(u);
				if(u.spm == u.name) outherSpms.push(u);
				if(u.name == '李晓利') this.normWork = u.pbData;
			}
			this.spms = spms;this.outherSpms = outherSpms;
		},
		async refresh(){//刷新数据
			this.dbDatas = await this.$db.collection('cms-gyy-user').limit(1000).get().then(res=>res.data);
		},
		async bindSpm(){
			if(this.selSpm){
				this.$confirm('绑定账号后暂时无法解绑，需谨慎操作。继续请确认。').then(async () => {
					await this.$db.collection('cms-gyy-user').doc(this.selSpm).update({userid:this.userInfo.uuid});
					this.$router.go(0);
				});
			}else{
				this.$message({message: `请先选择需要绑定的人员`,type: 'error'});
			}
		},
		setPbObj(obj,value,ymd){//写入单个排班对象
			if(value=='加班'){//加班
				obj.isOvertime[ymd] = true;
			}else if(value=='不打卡'){//免签到
				obj.visaFree[ymd] = true;
			}else if(value=='清理'){//清理
				obj.pbData[ymd] = '';
				obj.isOvertime[ymd] = this.$db.command.remove();
				obj.visaFree[ymd] = this.$db.command.remove();
			}else{//强制修改排班
				obj.pbData[ymd] = `*${value}*`;//写入更改排班
			}
			obj.updateTime = new Date();//更新时间
		},
		async updatePbData(saveObj){//更新数据库对象
			let load = this.$loading(),promiseArr = [];
			for(let key in saveObj){
				promiseArr.push(this.$db.collection('cms-gyy-user').doc(key).update(saveObj[key]));
			}
			let res = await Promise.all(promiseArr);//等待全部处理成功
			console.log(res);
			await this.getPbDate();//刷新数据
			load.close();
			this.$message({
				message: '排班信息调整成功',
				type: 'success',
			});
		},
		async savePbChange(){//保存排班信息修改
			if(this.pbForm.datas.length==0) return;
			for (let index in this.pbForm.datas) {
				if(!await this.$refs['pbForm'+index][0].validate().catch(()=>{1;})) return this.$message({message: `请检查必填项`,type: 'error'});
			}
			let obj = {pbData:{},isOvertime:{},visaFree:{}};
			for (let item of this.pbForm.datas) {
				this.setPbObj(obj,item.mode,item.date);//写入单个排班到obj
			}
			await this.updatePbData({[this.pbForm._id]:obj});
			await this.refresh();
			this.dialogPbFormVisible = false;
		},
		async savePbsChange(){//批量修改排班
			if(this.pbsForm.length==0) return;
			let saveData = {};
			for (let index in this.pbsForm) {
				let form = this.pbsForm[index]
				if(!await this.$refs['pbsForm'+index][0].validate().catch(()=>{1;})) return this.$message({message: `请检查必填项`,type: 'error'});
				
				if(form._ids[0] != 'all'){//正常修改
					let obj = {pbData:{},isOvertime:{},visaFree:{}},nowDate = new Date(form.dates[0]);//new一个新的时间对象，避免数据跟着变
					while(nowDate <= form.dates[1]){//对日期进行循环
						let value = (form.rest && this.normWork[nowDate.format()].replace(/\*/g,'') == '休')?'休':form.value;//是否开节假日排休
						this.setPbObj(obj,value,nowDate.format());//写入单个排班到obj
						nowDate.setDate(nowDate.getDate()+1);//加一天
					}
					for (let _id of form._ids) {//遍历用户写入日期班次情况
						if(saveData[_id]){//已存在的用户，需要合并
							for (let key in obj) {//对pbData和isOvertime进行叠加合并操作
								Object.assign(saveData[_id][key],obj[key]);
							}
						}else{//否则直接赋值即可
							saveData[_id] = obj;
						}
					}
				}else{//全局修改
					for (let user of this.dbDatas) {//遍历用户写入日期班次情况
						let obj = {pbData:{},isOvertime:{},visaFree:{}},nowDate = new Date(form.dates[0]);
						while(nowDate <= form.dates[1]){//对日期进行循环
							let nowVal = user.pbData[nowDate.format()];
							if(nowVal && (!form.rest || nowVal.replace(/\*/g,'') != '休')){//存在班次，且不为休息日或者不跳过休息日
								
								this.setPbObj(obj,form.value,nowDate.format());//写入单个排班到obj
							}
							nowDate.setDate(nowDate.getDate()+1);//加一天
						}
						saveData[user._id] = obj;
					}
				}
			}
			for(let key in saveData){if(!saveData[key].updateTime) delete saveData[key]}
			console.log(saveData);
			await this.updatePbData(saveData);//批量保存到数据库
			await this.refresh();
			this.dialogPbsFormVisible = false;
		},
		async saveInfoChange(){//保存组员基本信息修改
			if(!await this.$refs.infoForm.validate().catch(()=>{1;})) return this.$message({message: `请检查必填项`,type: 'error'});
			let {_id,...obj} = this.infoForm;
			let load = this.$loading();
			let result;
			if(_id){//修改
				result = await this.$db.collection('cms-gyy-user').doc(_id).update(obj);
			}else{//新增
				let pbData = {};
				for (let date of this.sDateList) {//新员工当月默认早班
					if(new Date(obj.joinDate) <= new Date(date)){//入职日期之后的日期
						pbData[date] = (this.normWork[date].replace(/\*/g,'') == '休'?'休':'早');
					}
				}
				result = await this.$db.collection('cms-gyy-user').add({...obj,pbData,visaFree:{[obj.joinDate]:true}});
			}
			console.log(result);
			await this.getPbDate();//刷新数据
			await this.refresh();
			load.close();
			this.$message({
				message: '信息处理成功',
				type: 'success',
			});
			this.dialogInfoFormVisible = false;
		},
		async outUser(_id){
			let load = this.$loading();
			await this.$db.collection('cms-gyy-user').doc(_id).update({spm:''});
			await this.getPbDate();//刷新数据
			load.close();
			this.$message({
				message: '组员已离组',
				type: 'success'
			});
		},
		async addUser(_id){
			let load = this.$loading();
			await this.$db.collection('cms-gyy-user').doc(_id).update({spm:this.userInfo.spm});
			await this.getPbDate();//刷新数据
			load.close();
			this.$message({
				message: '认领组员成功',
				type: 'success'
			});
		},
		async deleteUser(_id){//删除用户
			let load = this.$loading();
			await this.$db.collection('cms-gyy-user').doc(_id).remove();
			await this.getPbDate();//刷新数据
			load.close();
			this.$message({
				message: '用户已删除',
				type: 'success'
			});
		},
		async importJb(file){
			let _this = this;
			readWorkbookFromLocalFile(file.raw,async function(e){
				let jbDatas = XLSX.utils.sheet_to_json(e.Sheets[e.SheetNames[0]]);
				let saveData = {},nowMonth = new Date(_this.date_[0]).getMonth()+1;
				for(let obj of jbDatas){
					let oldDate = new Date(obj['加班日期']);
					//oldDate.setDate(oldDate.getDate()+1)
					let _id = _this.phoneObj[obj['手机']], date = oldDate.format();
					if(_id && nowMonth == new Date(date).getMonth()+1){//只导入手机号存在且属于当前月份的
						if(saveData[_id]){
							saveData[_id].isOvertime[date] = true;
						}else{
							saveData[_id] = {isOvertime:{[date]:true},name:obj['姓名']};
						}
					}
				}
				_this.jbSaveData = saveData;
			})
			function readWorkbookFromLocalFile(file, callback) {
				var reader = new FileReader();
				reader.onload = function(e) {
					var data = e.target.result;
					var workbook = XLSX.read(data, {type: 'binary',cellDates: true});
					if(callback) callback(workbook);
				};
				reader.readAsBinaryString(file);
			}
		},
		copyObj(obj,formObj){
			for (let key in obj) {
				obj[key] = formObj[key]==undefined?'':formObj[key];
			}
			return obj;
		},
		getStyle(d,date){
			let bc_obj = {};
			if(d.isOvertime[date]){//加班
				bc_obj = {color:'#FF9800'};
			}else{
				bc_obj = {
					'休':{color:'#8bc34a'},
					'事':{color:'#2196f3'},
					'夜班':{color:'red'}
				}[d.pbData[date].replace(/\*/g,'')]
			}
			return bc_obj;
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.content{padding-top: 40px;}
	.pb-list-cell{height: 44px;margin: -8px;padding: 8px;}
	.pb-list-cell>p {text-align: center;margin: 0;}
	.pb-list-cell .cell-date {margin: 0;font-size: 20px;}
</style>
<style>
	.pb-list-calendar .el-calendar__body {padding-top: 0;}
	.pb-list-calendar .el-calendar-table .el-calendar-day {height: 60px;}
</style>
