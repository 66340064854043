<template>
	<el-container>
	  <el-aside width="200px">
			<el-menu style="height: 100vh;" :router="true" :default-active="$router.currentRoute.path">
				<div><h4 style="text-align: center;margin-bottom: 0;">员工信息管理系统</h4></div>
				<el-menu mode="horizontal">
				  <el-submenu style="width: 100%;text-align: center;" index="-1">
						<template slot="title">{{userInfo.spm||userInfo.username}}</template>
						<el-menu-item @click="$router.push('/updatePwd');" index="1">修改密码</el-menu-item>
						<el-menu-item @click="outLogin()" index="-1">退出登录</el-menu-item>
					</el-submenu>
				</el-menu>
				<template v-for="menu,index in showMenus">
					<template v-if="menu.show">
						<el-submenu v-if="menu.children" :index="index+''" :key="index">
							<template slot="title"> <i :class="menu.icon"></i> <span>{{menu.name}}</span> </template>
							<el-menu-item v-for="child in menu.children" :key="child.path" :index="child.path">
								<i :class="child.icon"></i>
								<span slot="title">{{child.name}}</span>
							</el-menu-item>
						</el-submenu>
						<el-menu-item v-else :index="menu.path" :key="index">
							<i :class="menu.icon"></i>
							<span slot="title">{{menu.name}}</span>
						</el-menu-item>
					</template>
				</template>
			</el-menu>
		</el-aside>
	  <el-main style="height: 100vh;padding: 0px 5px;">
			<router-view/>
		</el-main>
	</el-container>
</template>

<script>
	export default {
		name: 'index',
		data () {
			return {
				menus:[
					{name:'系统管理',icon:'el-icon-s-tools','系统管理员':true,children:[
						{path:'/demo',name:'单页增删改',icon:'el-icon-s-order'},
						{path:'/users',name:'用户管理',icon:'el-icon-user-solid'},
					]},
					{name:'员工管理',icon:'el-icon-s-custom',show:true,children:[
						{path:'/userList',name:'员工信息',icon:'el-icon-user-solid'},
						{path:'/application',name:'考勤管理',icon:'el-icon-user-solid'},
						{path:'/pb',name:'班次信息',icon:'el-icon-date'},
						{path:'/count',name:'排班统计',icon:'el-icon-s-order'},
					]},
					{name:'考核管理',icon:'el-icon-s-claim','排班系统超级管理员':true,'系统管理员':true,children:[
						{path:'/countAndon',name:'工单指标',icon:'el-icon-s-marketing'},
						{path:'/countGrade',name:'考核管理',icon:'el-icon-finished'},
					]},
					{path:'/grade',name:'绩效考核',icon:'el-icon-s-order',show:true},
				],
				power:{},
				userInfo:{roles:[]}
			}
		},
		computed:{
			showMenus(){
				let menus = this.menus.map(v=>{
					['SPM','考勤管理员','排班系统超级管理员','系统管理员'].map(type=>{//默认设置为true
						if(v[type]==true && this.userInfo.roles.includes(type)) v['show'] = true;
					})
					if(v.name=='员工管理' && this.userInfo.roles.includes('导师')) v['show'] = false;//导师没有员工管理
					return v;
				})
				return menus;
			}
		},
		async created(){
			this.userInfo = await this.$getUserInfo();
		},
		methods:{
			isShow(menu){
				for (let role of this.userInfo.roles) {
					if(menu[role]) return true;
				}
				return false;
			},
			async outLogin() {//退出登录
				let load = this.$loading();
				await this.$auth.signOut();
				load.close();
				this.$router.push('/login');
			}
		}
	}
</script>

<style>
	body{
		width: 100%;
		overflow-x: hidden;
	}
</style>
