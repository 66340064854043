<template>
	<el-container>
		<el-aside width="430px">
			<h1 style="text-align: center;">考核任务列表</h1>
			<el-table :data="taskData" style="width: 100%">
				<el-table-column align="center"  label="季度"  width="70">
					<template slot-scope="scope">{{scope.row.year+'-'+scope.row.jd}}</template>
				</el-table-column>
				<el-table-column align="center"  prop="startTime" label="开始时间"  width="100">
					<template slot-scope="scope"> {{new Date(scope.row.startTime).format('YYYY-MM-DD')}} </template>
				</el-table-column>
				<el-table-column align="center"  prop="status" label="状态"  width="100">
					<template slot-scope="scope">
						<el-tooltip effect="dark" placement="top" :content="scope.row.status==0?'尚未结束':`${new Date(scope.row.endTime).format('YYYY-MM-DD')}`">
							<el-tag size="small" :type="scope.row.status==0?'':'warning'">{{scope.row.status==0?'进行中':'已结束'}}</el-tag>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column align="center"  label="操作"  width="160">
					<template slot="header">
						<el-button type="primary" size="mini" @click="isAddNewJd = true" plain>开启新季度考核</el-button>
					</template>
					<template slot-scope="scope">
						<el-dropdown style="margin-right: 3px;">
							<el-button size="mini">
								更多<i class="el-icon-arrow-down el-icon--right"></i>
						  </el-button>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item @click.native="checkInfo(scope.row)">查看汇总</el-dropdown-item>
						    <el-dropdown-item @click.native="checkInfo(scope.row,'SPM')">查看SPM评分</el-dropdown-item>
						    <el-dropdown-item @click.native="checkInfo(scope.row,'导师')">查看导师评分</el-dropdown-item>
								<el-dropdown-item @click.native="showImport(scope.row)">导入数据</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
						<el-button v-if="scope.row.status==0" size="mini" type="danger" @click="changeStatus(scope.row._id)">结算</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-aside>
	  <el-main style="height:calc(100vh - 60px);">
			<h1 style="text-align: center;margin-top: 0;margin-bottom: 10px;"><span v-if="param.year">{{`${param.year}年第${param.jd}季度考核数据展示`}}</span></h1>
			<el-table class="tb-edit" ref="table" :data="tableData" style="margin: 0 auto; width: max-content;" >
				<el-table-column align="center" prop="name" label="员工姓名" width="100"> </el-table-column>
				<el-table-column align="center" prop="userNo" label="工号" width="100"> </el-table-column>
				<el-table-column align="center" prop="spm" label="SPM" width="100"> </el-table-column>
				<el-table-column align="center" prop="scoreMentor" label="绩效导师" width="100"> </el-table-column>
				<el-table-column align="center" label="业绩指标（65%）">
					<el-table-column align="center" prop="plan" width="80">
						<template slot="header">工作进度<br>（30%）</template>
					</el-table-column>
					<el-table-column align="center" prop="quality" label="" width="80">
						<template slot="header">工作质量<br>（35%）</template>
					</el-table-column>
				</el-table-column>
				<el-table-column align="center" prop="weight1" label="加权总得分" width="80"></el-table-column>
				<el-table-column align="center" label="能力素质指标（35%）">
					<el-table-column align="center" prop="ability" width="80">
						<template slot="header">能力指标<br>（10%）</template>
					</el-table-column>
					<el-table-column align="center" prop="daily" width="80">
						<template slot="header">日常工作表现<br>（15%）</template>
					</el-table-column>
					<el-table-column align="center" prop="team" width="80">
						<template slot="header">团队协作性<br>（10%）</template>
					</el-table-column>
				</el-table-column>
				<el-table-column align="center" prop="weight2" label="加权总得分" width="80"></el-table-column>
				<el-table-column align="center" prop="extra" label="额外加减分项" width="80"></el-table-column>
				<el-table-column align="center" prop="score" label="综合考评得分" width="80" :sortable="true" :sort-method="sortScore"></el-table-column>
				<el-table-column align="center" prop="grade" label="评级" width="60"></el-table-column>
				<el-table-column align="center" prop="rank" label="排名" width="60"></el-table-column>
				<el-table-column align="center" prop="last" label="上季度数据" class-name="last-h" >
					<el-table-column align="center" class-name="last-h" label="总分" width="70"><template scope="scope">{{scope.row.last?scope.row.last.score:''}}</template></el-table-column>
					<el-table-column align="center" class-name="last-h" prop="last.grade" label="评级" width="60"><template scope="scope">{{scope.row.last?scope.row.last.grade:''}}</template></el-table-column>
					<el-table-column align="center" class-name="last-h" prop="last.rank" label="排名" width="60"><template scope="scope">{{scope.row.last?scope.row.last.rank:''}}</template></el-table-column>
					<el-table-column align="center" prop="rank_x" class-name="last-h" label="名次变化" width="100" :sortable="true" :sort-method="sortRank_x">
						<template scope="scope">
							<span v-if="scope.row.last" :style="{color:scope.row.rank_x>0?'#67C23A':'#F56C6C'}">{{scope.row.rank_x}}<i style="font-weight: 800;" :class="scope.row.rank_x>0?'el-icon-top':scope.row.rank_x<0?'el-icon-bottom':''"></i></span>
						</template>
					</el-table-column>
					<!-- <el-table-column align="center" prop="plan" label="名次比率变化" width="110"></el-table-column> -->
				</el-table-column>
			</el-table>
		</el-main>
		<el-dialog width="800px" title="开启新季度考核数据填写计划" :visible.sync="isAddNewJd">
			<el-form ref="planForm" size="mini" :model="newJdData" label-width="80px">
				<el-form-item label="考核年份" prop="year" :rules="[{ required: true, message: '必填'}]">
					<el-date-picker v-model="newJdData.year" type="year" value-format="yyyy" placeholder="选择年份"> </el-date-picker>
				</el-form-item>
				<el-form-item label="考核季度" prop="jd" :rules="[{ required: true, message: '必填'}]">
					<el-select v-model.number="newJdData.jd" placeholder="请选择季度">
						<el-option v-for="n in 4" :key="n" :label="`第${n}季度`" :value="n"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入职时间筛选" prop="joinDate" :rules="[{ required: true, message: '必填'}]">
					<el-date-picker v-model="newJdData.joinDate" value-format="yyyy-MM-dd" type="date" placeholder="入职时间大于填写时间才参与考核"></el-date-picker>
				</el-form-item>
				<el-form-item label="备注(描述)">
					<el-input type="textarea" v-model="newJdData.remark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="isAddNewJd = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="addNewJd">提 交</el-button>
			</div>
		</el-dialog>
		<el-dialog width="800px" title="导入季度考核数据" :visible.sync="isImport">
			<el-form ref="importData" size="mini" :model="importData" label-width="80px">
				<el-form-item label="年份季度" >
					<el-input v-model="importData.year_jd" placeholder="请输入内容" disabled ></el-input>
				</el-form-item>
				<el-form-item label="导入类别" prop="type" :rules="[{ required: true, message: '必填'}]">
					<el-select v-model="importData.type" placeholder="请选择">
						<el-option label="导师" value="导师"></el-option>
						<el-option label="SPM" value="SPM"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="导入文件" :multiple="true" prop="type" :rules="[{ required: true, message: '必填'}]">
					<el-upload action="/" ref="uploadFile" :auto-upload="false">
						<el-button size="mini" type="primary">选择文件</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="isImport = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="importStart">导 入</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>
<script>
import XLSX from 'xlsx'
export default {
	name:'countGrade',
	data(){
		return {
			param:{},//年份季度
			data:[],
			spmObj:{},
			sObj:{S:0.3,A:0.4,B:0.2,CD:0.1},
			newJdData:{year:'',jd:'',joinDate:'',remark:''},//新季度考核数据填写计划数据
			isAddNewJd:false,//开启新季度考核数据填写计划窗口显示状态
			tableData:[],
			taskData:[{year:'2021',jd:'1',joinDate:'2021-04-25',remark:'测试',startTime:'2021-06-25',endTime:'2021-07-25'}],
			isImport:false,
			importData:{year_jd:'',type:'导师'},
		}
	},
	computed:{
		name_id(){
			let obj = {};
			for (let u of this.data) {
				obj[u.name+u.scoreMentor.substring(u.scoreMentor.indexOf('(')+1,u.scoreMentor.indexOf(')'))] = u._id;
			}
			return obj;
		}
	},
	async created(){
		this.$db = this.$tcb.database();
		this.init();
	},
	methods:{
		getGrade(row){//获取评级
			return row.grade = row.score>=95?'S':row.score>=90?'A':row.score>=85?'B':row.score>=80?'C':'D';
		},
		sortScore(a,b){
			return a.score?-9999:a-b;
		},
		sortRank_x(a,b){
			return a.rank_x?-9999:a-b;
		},
		async init(){
			this.data = await this.loding(this.$db.collection('cms-gyy-user').where({isJob:true,isGrade:true}).field({_id:1,name:1,userNo:1,spm:1,scoreMentor:1,joinDate:1,performance:1}).limit(1000).get()).then(r=>{return r.data;});
			this.taskData = await this.loding(this.$db.collection('quarter-plan').limit(100).get()).then(r=>{return r.data;});
			this.spmObj = await this.$tcb.callFunction({name:'getUsers',data:{roles:['SPM']}}).then(res=>{//写入导师和spm
				let obj = {'颜吉军':true,'杨永辉':true};
				for (let v of res.result.data) {obj[v.name] = true}
				return obj;
			})
			console.log(this.spmObj)
		},
		async loding(pro){//接受一个prmise对象，封装loding过程
			let load = this.$loading();
			let res = await pro;
			load.close();
			return res;
		},
		async addNewJd(){
			if(!await this.$refs.planForm.validate().catch(()=>{1;})) return this.$message({message: `请检查必填项`,type: 'error'});
			let obj = {startTime:new Date(),status:0,...this.newJdData,year:this.newJdData.year-0};
			console.log(obj)
			let res = await this.loding(this.$db.collection('quarter-plan').add(obj));
			console.log(res);
			this.$message({message: `【${this.newJdData.year}第${this.newJdData.jd}季度】考核计划创建成功，评分收集开始。`,type: 'success'});
			this.isAddNewJd = false;
			this.init();
		},
		async changeStatus(id){
			this.$confirm('请确认信息收集完整，确认结束考核任务吗?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(async() => {
				let res = await this.loding(this.$db.collection('quarter-plan').doc(id).update({endTime:new Date(),status:1}));
				console.log(res);
				this.$message({message: '考核任务已结束。',type: 'success'});
				this.init();
			});
		},
		checkInfo(row,type){
			this.param = {year:row.year,jd:row.jd};
			let now_jd = `${row.year}-${row.jd}`;
			let l_jd =  row.jd>1?`${row.year}-${row.jd-1}`:`${row.year-1}-4`;
			let arr = [],lastList=[];
			for(let u of this.data){
				if(new Date(u.joinDate) > new Date(row.joinDate)) continue;//跳过不符合条件的同学
				let jcObj = {id:u._id,name:u.name,spm:u.spm,scoreMentor:u.scoreMentor,userNo:u.userNo};
				if(type){//按类型查看
					if(u.performance && u.performance[type]){
						if(u.performance[type][l_jd]) lastList.push({id:u._id,...u.performance[type][l_jd]});//用作排名数据排序
						arr.push({...jcObj,...(u.performance[type][now_jd]||{}),last:u.performance[type][l_jd]||{}});
					}else{
						arr.push(jcObj);
					}
				}else if(u.performance&&((u.performance['SPM']&&u.performance['SPM'][now_jd]||this.spmObj[u.name]) && u.performance['导师']&&u.performance['导师'][now_jd])){//都已打分才统计
					let l_data = {},data = {},isSpm = this.spmObj[u.name];
					['plan','quality','weight1','ability','daily','team','weight2','extra','score'].map(key=>{
						data[key] = ((isSpm?0:u.performance['SPM'][now_jd][key]*0.3)+u.performance['导师'][now_jd][key]*(isSpm?1:0.7)).toFixed(2)*100/100;
					})
					this.getGrade(data);
					//console.log(u)
					l_data.score = u.performance['导师'][l_jd]?((isSpm?0:u.performance['SPM'][l_jd].score*0.3)+u.performance['导师'][l_jd].score*(isSpm?1:0.7)).toFixed(2)*100/100:0;
					//u.performance['导师'][l_jd]?(u.performance['SPM'][l_jd].score*0.3+u.performance['导师'][l_jd].score*0.7).toFixed(2)*100/100:0;
					if(l_data.score){
						this.getGrade(l_data);
						lastList.push({id:u._id,...l_data});
						data.last = l_data;
					}
					arr.push({...jcObj,...data});
				}else{
					arr.push(jcObj);
				}
			}
			arr = arr.sort((a,b)=>{return (b.score||0) - (a.score||0)});//倒叙用作排名数据获取
			lastList = lastList.sort((a,b)=>{return (b.score||0) - (a.score||0)});//倒叙用作排名数据获取
			//console.log(lastList);
			for (let i = 0; i < arr.length; i++) {
				arr[i].rank = i+1;
				for (let j = 0; j < lastList.length; j++) {
					if(lastList[j].id == arr[i].id) arr[i].last.rank = j+1;
				}
				if(arr[i].last) arr[i].rank_x = arr[i].last.rank - arr[i].rank;
			}
			this.tableData = arr;
			let nullObj={},mentorObj={};
			for (let obj of arr) {
				if(!obj.score){
					if(type == 'SPM'){
						nullObj[obj.spm] = nullObj[obj.spm]?nullObj[obj.spm]+1:1;
					}else if(type == '导师'){
						if(nullObj[obj.spm]){
							nullObj[obj.spm][obj.scoreMentor] = nullObj[obj.spm][obj.scoreMentor]?nullObj[obj.spm][obj.scoreMentor]+1:1;
						}else{
							nullObj[obj.spm] = {[obj.scoreMentor]:1};
						}
					}
				}
			}
			console.log(nullObj);
		},
		async showImport(row){
			this.importData.year_jd = `${row.year}-${row.jd}`;
			this.isImport = true;
		},
		async importStart(){
			let promises = [];
			for(let file of this.$refs.uploadFile.uploadFiles){
				let e = await this.readeFile(file.raw);
				let fileDatas = XLSX.utils.sheet_to_json(e.Sheets[e.SheetNames[0]]);//取第一个shell
				for (let obj of fileDatas) {
					let {scoreMentor,name,plan,quality,weight1,ability,daily,team,weight2,extra,score,grade} = obj;
					promises.push(this.$db.collection('cms-gyy-user').doc(this.name_id[name+scoreMentor]).update({
						//console.log(this.name_id[name+scoreMentor],name,{
							performance:{
								[this.importData.type]:{
									[this.importData.year_jd]:{
										plan,quality,weight1,ability,daily,team,weight2,extra:extra||0,score,grade,saveDate:new Date().format('YYYY-MM-DD hh:mm:ss'),
									},
								},
							},
						//});
						})
					);
				}
				await Promise.all(promises).then(res=>console.log(res));
			}
		},
		async readeFile(file){
			console.log(file);
			return new Promise((r,err)=>{
				var reader = new FileReader();
				reader.onload = function(e) {
					var data = e.target.result;
					var workbook = XLSX.read(data, {type: 'binary',cellDates: true});
					r(workbook);
				};
				reader.readAsBinaryString(file);
			});
		},
	}
}
</script>

<style>
.el-table thead.is-group th.last-h{
	background: #909399;
	color: #607d8b;
}
.el-table th.el-table__cell {
	user-select: initial;
}
</style>
