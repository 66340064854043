<template>
	<el-container>
	  <el-header :style="{height:headerHeight,'text-align':'center'}">
			<h1 v-if="param.year" style="margin: 5px; margin-top: 10px;">
				{{param.year}}年第{{param.jd}}季度员工绩效考核
				<el-button size="mini" style="margin-left: 20px;" @click="showTips=true">查看评分规则</el-button>
			</h1>
			<div style="margin: 0 auto;width: 720px;">
				<el-alert :title="`您当前需要评分总人数(${tableData.length})人。S级别最高评(${maxNumObj.S})人，A级别最高评(${maxNumObj.A})人，其余等级暂不限制。`" type="warning" close-text="知道了" @close="headerHeight = '40px'"> </el-alert>
			</div>
		</el-header>
	  <el-main :style="{height:`calc(100vh - ${headerHeight})`,padding:'5px'}" >
			<el-empty v-if="!param.year" :image-size="300" description="暂时没有开启状态的考核任务"></el-empty>
			<el-table v-else class="tb-edit" ref="table" :data="tableData" height="100%" style="margin: 0 auto; width: max-content;" highlight-current-row @row-click="handleCurrentChange">
				<el-table-column align="center" prop="name" label="员工姓名" width="100"> </el-table-column>
				<el-table-column align="center" label="业绩指标（65%）">
					<el-table-column align="center" prop="plan" width="110">
						<template slot="header">工作进度<br>（30%）</template>
						<template scope="scope">
							<!-- <el-input-number size="small" v-model.number.number="scope.row.plan" :min="0" :max="100" :controls="false" :precision="2" placeholder="得分" ></el-input-number> -->
							<el-input size="small" type="number" v-model.number="scope.row.plan" @input="inputS(scope.row,'plan')" :precision="2" placeholder="得分" ></el-input> <span>{{scope.row.plan}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="quality" label="" width="110">
						<template slot="header">工作质量<br>（35%）</template>
						<template scope="scope">
							<el-input size="small" type="number" v-model.number="scope.row.quality" @input="inputS(scope.row,'quality')" placeholder="得分" ></el-input> <span>{{scope.row.quality}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column align="center" prop="weight1" label="加权总得分" width="100">
					<template scope="scope">
						<span>{{(scope.row.weight1 = scope.row.plan*0.3+scope.row.quality*0.35).toFixed(2)*100/100||0}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="能力素质指标（35%）">
					<el-table-column align="center" prop="ability" width="110">
						<template slot="header">能力指标<br>（10%）</template>
						<template scope="scope">
							<el-input size="small" type="number" v-model.number="scope.row.ability" @input="inputS(scope.row,'ability')" placeholder="得分" ></el-input> <span>{{scope.row.ability}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="daily" width="110">
						<template slot="header">日常工作表现<br>（15%）</template>
						<template scope="scope">
							<el-input size="small" type="number" v-model.number="scope.row.daily" @input="inputS(scope.row,'daily')" placeholder="得分" ></el-input> <span>{{scope.row.daily}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="team" width="110">
						<template slot="header">团队协作性<br>（10%）</template>
						<template scope="scope">
							<el-input size="small" type="number" v-model.number="scope.row.team" @input="inputS(scope.row,'team')" placeholder="得分" ></el-input> <span>{{scope.row.team}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column align="center" prop="weight2" label="加权总得分" width="100">
					<template scope="scope">
						<span>{{(scope.row.weight2 = scope.row.ability*0.1+scope.row.daily*0.15+scope.row.team*0.1).toFixed(2)*100/100||0}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="extra" label="额外加减分项" width="100">
					<template scope="scope">
						<el-input size="small" type="number" v-model.number="scope.row.extra" @input="inputS(scope.row,'extra')" placeholder="得分" ></el-input> <span>{{scope.row.extra}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="score" label="综合考评得分" width="100">
					<template scope="scope">
						<span>{{getCountScore(scope.row)||0}}</span>
						<el-tooltip v-if="scope.row.oldScore>100" class="item" effect="dark" :content="`总分值为${scope.row.oldScore}，超过最大值100，自动设为最大值。`" placement="top-start">
							<i class="el-icon-warning" style="font-size: 16px;color: #F56C6C;"></i>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="grade" label="评级" width="80">
					<template scope="scope">
						<span>{{getGrade(scope.row)}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="saveDate" label="提交状态" width="80">
					<template scope="scope">
						<el-tooltip v-if="scope.row.saveDate" effect="dark" :content="scope.row.saveDate" placement="top">
							<i class="el-icon-success" style="font-size: 20px;color: #03a9f4;"></i>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="isChange" label="操作" width="100">
					<template scope="scope">
						<el-button v-if="scope.row.isChange" size="mini" @click="saveChange(scope.row)" >提交</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-main>
		<el-dialog title="评分规则" :visible.sync="showTips" width="1200px">
			<el-alert :title="`评分需要遵循${ruleStr}的占比，但是CD评分不强制要求。总分超过100会自动修正。`" type="warning" :closable="false"> </el-alert>
			<div class="el-table el-table--border">
				<table class="el-table__header ">
					<thead class="is-group has-gutter">
						<tr>
							<th class="is-center el-table__cell" width="120px"><div class="cell">模块</div></th>
							<th class="is-center el-table__cell" width="150px"><div class="cell">维度</div></th>
							<th class="is-center el-table__cell" width="400px"><div class="cell">具体工作任务</div></th>
							<th class="is-center el-table__cell" width="100px"><div class="cell">分值</div></th>
							<th class="is-center el-table__cell"><div class="cell">衡量标准</div></th>
						</tr>
					</thead>
					<tbody>
						<tr class="el-table__row">
							<td rowspan="2" class="is-center  el-table__cell"><div class="cell">业绩指标(65%)</div></td>
							<td class="is-center  el-table__cell"><div class="cell">工作进度(30%)</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									1.专项工程师处处理工单量、二线产品运维综合评价；
									<br/>2.技术赋能，打造团队技术影响力；
									<br/>3.内容沉淀，打造最佳实践：将日常支持中可通过文档等方式沉淀的解决方法、话术等；
									<br/>4.checklist/培训视频/云+社区等方式进行工作成果记录；
									<br/>5.通过技术手段加速产品顽疾改进，提升产品稳定性；
								</div>
							</td>
							<td class="is-center  el-table__cell"><div class="cell">100分</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									工作进度衡量标准：期内主要由腾讯侧导师对本岗位人员安排的工作任务实际完成进度评估。评分标准参考：
									<br/>100-90分，积极主动，超前完成工作任务；
									<br/>90-80分，循序渐进安排工作，按时间节点完成任务；
									<br/>80-70分，因个人原因不能按期完成工作任务；
									<br/>70分以下，工作进度严重滞后，无法完成工作任务。
								</div>
							</td>
						</tr>
						<tr class="el-table__row">
							<td class="is-center  el-table__cell"><div class="cell">工作质量(35%)</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									1.安全：安全合规完成工作，不触碰公司高压线：
									<br/>2.效率：较少的工单回复次数，较短的工单处理时长；
									<br/>3.服务：良好的服务质量，较高的客户满意度；
									<br/>4.专业性：向后端的透传率小于15%，质检合格率大于90%，客户不满意率小于3%；
									<br/>5.积极参与公司要求的培训赋能，并取得积极反馈；
									<br/>6.输出高质量，专业性的文档等知识沉淀，产生社区个人影响力。
								</div>
							</td>
							<td class="is-center  el-table__cell"><div class="cell">100分</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									工作质量衡量标准：期内对本岗位工作内容完成质量的评估。评分标准参考：
									<br/>100-90分，工作效果完美，非常满意；
									<br/>90-80分，达到工作要求，有提升空间；
									<br/>80-70分，尚有一定差距，工作质量待改善；
									<br/>70分以下，基本不能满足本岗位要求，工作完成很差。
								</div>
							</td>
						</tr>
						<tr class="el-table__row">
							<td rowspan="3" class="is-center el-table__cell"><div class="cell">能力素质(35%)</div></td>
							<td class="is-center  el-table__cell"><div class="cell">能力指标(10%)</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									评分标准参考：                                                            
									<br/>100-95分，工作目标性强，面对困难和问题时大多数情况能够想方设法解决，且解决情况好；
									<br/>95-85分，执行力强，理解目标后通常达成有一定难度的工作目标；
									<br/>80-85分，工作习惯不错，做出来的东西质量基本有保证；
									<br/>80-70分，基本能把控工作进度，但有时会拖延，工作质量有时会有缺失；
									<br/>70-60分，从不制定工作计划，工作中主次不分，导致工作效率低下；
									<br/>60分以下，工作效率低下、工作质量差，拖团队后腿，对整体工作目标的实现造成严重后果。
								</div>
							</td>
							<td class="is-center  el-table__cell"><div class="cell">100分</div></td>
						</tr>
						<tr class="el-table__row">
							<td class="is-center  el-table__cell"><div class="cell">日常工作表现(15%)</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									1、不听从工作安排，减10分/次
									<br/>2、上班时间从事非工作类活动（如看网页、逛淘宝、看视频、看小说、玩游戏、与工作无关的微信聊天等），减10分/次
									<br/>3、领导安排的任务消极怠慢且无回复、无反馈，减10分/次
									<br/>4、遇重大问题未解决且未上报，减10分/次
									<br/>5、上班无故迟到、早退，被通报扣5分/次
									<br/>6、周报是否及时提交，被通报扣5分/次
									<br/>7、上班期间，无故离开座位30分钟以上，减5分/次
									<br/>8、请假次数/天数2天内，减5分；2天到5天减10分；大于5天，减20分
									<br/>9、其它影响工作效率或其他成员的活动，酌情扣分。
								</div>
							</td>
							<td class="is-center  el-table__cell"><div class="cell">100分</div></td>
						</tr>
						<tr class="el-table__row">
							<td class="is-center  el-table__cell"><div class="cell">团队协作性(10%)</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									100-90分：积极主动配合团队工作乐于助人，个人利益服从集体利益。
									<br/>90-80分：配合团队工作，保持良好沟通。
									<br/>80-70分：愿意配合，但需监督，不够积极主动。
									<br/>70分以下：不愿配合团队工作，因个人原因而影响整个团队工作。
								</div>
							</td>
							<td class="is-center  el-table__cell"><div class="cell">100分</div></td>
						</tr>
						<tr class="el-table__row">
							<td class="is-center  el-table__cell"><div class="cell">额外加减分项</div></td>
							<td class="is-center  el-table__cell"><div class="cell">根据事件严重程度酌情加减</div></td>
							<td class="  el-table__cell">
								<div class="cell">
									加分：
									<br/>1、提出合理化建议被采纳；
									<br/>2、对产品质量的提升提出改进、建议被采纳；
									<br/>3、工作中积极创新，提高工作效率或者工作质量的；
									<br/>4、发现安全隐患或者及时制止不良行为的；
									<br/>5、解决重大顽固问题；
									<br/>6、参加技能培训并取得职能认证证书；
									<br/>减分：
									<br/>1、不服从工作安排，拒绝上级安排的正常工作任务；
									<br/>2、玩忽职守、工作不负责任造成事故或损失；
								</div>
							</td>
							<td class="is-center  el-table__cell"><div class="cell">-5分至+5分</div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</el-dialog>
	</el-container>
</template>
<script>
export default {
	name:'grade',
	data(){
		let datas = [];
		for(let i=100;i>=50;i-=5){
			datas.push({name:'颜吉军'+i,plan:i,quality:i,ability:i,daily:i,team:i,extra:0});
		}
		return {
			param:{},//年份季度等信息 joinDate
			data:[],
			tableData:[],
			headerHeight:'80px',
			sObj:{S:0.3,A:0.4,B:0.2,CD:0.1},
			showTips:false,//显示评分提示
		}
	},
	async created(){
		this.userInfo = await this.$getUserInfo();
		this.userType = this.userInfo.roles.includes('SPM')?'SPM':this.userInfo.roles.includes('导师')?'导师':'';
		this.$db = this.$tcb.database();
		this.init();
	},
	computed:{
		year_jd(){
			return `${this.param.year}-${this.param.jd}`;
		},
		ruleStr(){
			let str = '';
			for (let pj in this.sObj) {
				str += `${pj}(${this.sObj[pj]*100}%)`;
			}
			return str;
		},
		maxNumObj(){//各等级最高评级人数
			let obj = {};
			for(let key in this.sObj){
				obj[key] = Math.ceil(this.tableData.length*this.sObj[key]);
			}
			return obj;
		}
	},
	methods:{
		async init(){
			this.param = await this.$db.collection('quarter-plan').where({status:0}).get().then(res=>{return res.data.length>0?res.data[0]:{}});
			if(!this.param.year) return;
			let userParam = this.userType=='SPM'?{spm:this.userInfo.spm,name:this.$db.command.neq(this.userInfo.spm)}:{scoreMentor:new RegExp(`${this.userInfo.username}\\(`)};
			userParam.isJob = true;
			this.data = await this.$db.collection('cms-gyy-user').where(userParam).field({name:1,joinDate:1,performance:1}).limit(100).get().then(res=>res.data);
			this.data.map((v)=>{
				if(new Date(v.joinDate.replace(/-/g,'/')) <= new Date(this.param.joinDate.replace(/-/g,'/'))){//入职时间要符合条件
					let obj = v.performance&&v.performance[this.userType]?(v.performance[this.userType][this.year_jd]||{}):{};
					this.tableData.push({id:v._id,name:v.name,...{plan:0,quality:0,ability:0,daily:0,team:0,extra:0},...obj});
				}
			});
		},
		inputS(row,key){//校验评分范围并自动纠正
			if(key == "extra"){
				console.log(row[key]);
				if(row[key])row[key] = (row[key]<-5?-5:row[key]>5?5:row[key]).toFixed(2)*100/100;
			}else{
				row[key] = (!row[key]?0:row[key]<0?0:row[key]>100?100:row[key]).toFixed(2)*100/100;
			}
			row.isChange = true;
		},
		getCountScore(row){//获取总分并矫正
			let score = (row.weight1+row.weight2+row.extra*1).toFixed(2)*100/100;
			row.oldScore = score;
			return row.score = score>100?100:score;
		},
		getGrade(row){//获取评级
			return row.grade = row.score>=95?'S':row.score>=90?'A':row.score>=85?'B':row.score>=80?'C':'D';
		},
		async saveChange(row){
			if(!this.checkSABCD(row)) return;
			let {id,plan,quality,weight1,ability,daily,team,weight2,extra,score,grade} = row;
			
			let load = this.$loading(), nowDate = new Date().format('YYYY-MM-DD hh:mm:ss');
			let res = await this.$db.collection('cms-gyy-user').doc(id).update({performance:{[this.userType]:{[this.year_jd]:{plan,quality,weight1,ability,daily,team,weight2,extra,score,grade,saveDate:nowDate}}}});
			load.close();
			this.$message({message: '保存成功',type: 'success'});
			
			row.isChange = false, row.saveDate = nowDate;
			setTimeout(()=>{this.$refs.table.setCurrentRow();},100);
		},
		checkSABCD(row){//校验评级占比是否允许提交
			if(row.grade =='B' || row.grade =='C' || row.grade =='D') return true;//BCD不限制
			let sum = 0, maxNum = this.maxNumObj[row.grade];
			for (let v of this.tableData) {
				if(row.id != v.id && v.saveDate){//非自身的已保存数据
					if(row.grade == v.grade) sum++;//计算相同已保存同级别数量
				}
			}
			if(sum+1 > maxNum){//已有数量和自身总和超过应有最大数量
				this.$message.error(`已有的【${row.grade}】级评分人员已达最大人数${maxNum}，请您先调整当前或其它人员数据`);
				return false;
			}
			return true;
		},
		handleCurrentChange(row, event, column) {
			//console.log(row, event, column, event.currentTarget)
		},
		handleEdit(row) {
			setTimeout(()=>{this.$refs.table.setCurrentRow();row.edited=false;},100)
		},
		handleDelete(index, row) {
			//console.log(index, row);
		}
	}
}
</script>

<style>
	.tb-edit .el-input {
		display: none
	}
	.tb-edit .current-row .el-input {
		display: block
	}
	.tb-edit .current-row .el-input+span {
		display: none
	}
	.tb-edit .el-input__inner {
		text-align: center;
	}
	input[type=number] {
	    -moz-appearance:textfield;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.el-dialog__body{
		padding: 20px;
	}
</style>
