<template>
	<el-container>
	  <el-header class="search" style="height: 50px;padding: 10px;">
			<el-row>
				<el-col :span="18">
					<el-form :inline="true" size="mini" :model="param">
						<el-form-item label="姓名">
							<el-input v-model="param.name" placeholder="姓名"></el-input>
						</el-form-item>
						<el-form-item label="地址">
							<el-select v-model="param.region" placeholder="地址">
								<el-option label="区域一" value="shanghai"></el-option>
								<el-option label="区域二" value="beijing"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search">查询</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="6" style="text-align: right;">
					<el-button type="primary" icon="el-icon-circle-plus-outline" @click="showInfo({},true)" size="mini">新建数据</el-button>
					<el-dropdown style="margin-left: 3px;">
						<el-button size="mini"> 更多<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item @click.native="test()">导入</el-dropdown-item>
					    <el-dropdown-item @click.native="test()">导出</el-dropdown-item>
					    <el-dropdown-item @click.native="test()">其它</el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown>
				</el-col>
			</el-row>
		</el-header>
	  <el-main style="padding: 0;height: calc(100vh - 100px);">
			<el-table :data="tableData" v-loading="tableLoad" size="mini" border height="100%" style="width: 100%">
				<el-table-column prop="date" label="日期" > </el-table-column>
				<el-table-column prop="name" label="姓名" > </el-table-column>
				<el-table-column prop="province" label="省份" > </el-table-column>
				<el-table-column prop="city" label="市区" > </el-table-column>
				<el-table-column prop="address" label="地址" > </el-table-column>
				<el-table-column prop="zip" label="邮编" > </el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="180">
					<template slot-scope="scope">
						<el-button @click="showInfo(scope.row,false)" type="text" icon="el-icon-search" size="mini">查看</el-button>
						<el-button @click="showInfo(scope.row,true)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
						<el-button @click="del(scope.row)" type="text" style="color: #f56c6c;" icon="el-icon-delete" size="mini">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-main>
		<el-footer style="height: 50px;padding: 10px;text-align: right;">
			<el-pagination
				@size-change="(v)=>{page.size = v;this.search()}"
				@current-change="(v)=>{page.nowPage = v;this.search()}"
				:current-page="page.nowPage"
				:page-sizes="[20, 50, 100, 500]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total">
			</el-pagination>
		</el-footer>
		<el-dialog title="信息表单" :close-on-click-modal="false" :visible.sync="infoFormShow">
			<el-form ref="infoForm" :model="infoForm" size="mini" label-width="100px" :disabled="!isEdit">
				<el-row>
					<el-col :span="12">
						<el-form-item prop="name" label="姓名" :rules="[{ required: true, message: '该项必填'}]">
							<el-input v-model="infoForm.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="地址">
							<el-input v-model="infoForm.province"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="infoFormShow = false">{{isEdit?'取 消':'确 认'}}</el-button>
				<el-button v-if="isEdit" size="mini" type="primary" @click="saveData">提 交</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>

<script>
export default{
	name:'demo',
	data() {
		return {
			tableName:'test',
			param: {name: '',region: ''},
			tableData: [],
			page:{total:0,size:20,nowPage:1},//分页参数
			isEdit:false,
			infoFormShow:false,
			infoForm:{},
			tableLoad:false,//默认加载状态
		}
	},
	async created(){
		this.$db = this.$tcb.database();//写入数据库对象
		await this.search();
	},
	methods:{
		async loadFun(fun){//
			const loading = this.$loading();
			let res = await fun;
			loading.close();
			return res;
		},
		async search(page){//查询数据
			if(this.tableLoad == true) return;//不可以重复查询
			let param = {};//查询条件
			for (let key in this.param) {
				if(this.param[key] || this.param[key] === false) param = this.param[key];
			}
			this.tableLoad = true;//表格加载中
			let colle = this.$db.collection(this.tableName).where(param);
			this.tableData = await colle.limit(this.page.size).skip(this.page.size*(this.page.nowPage-1)).get().then(res=>res.data);//查表逻辑
			this.page.total = await colle.count().then(res=>res.total);
			this.tableLoad = false;//表数据加载完毕
		},
		showInfo(row,isEdit){//查看编辑新增弹出窗
			this.isEdit = isEdit;//是否编辑态
			this.infoForm = JSON.parse(JSON.stringify(row));//写入数据
			this.infoFormShow = true;//显示弹窗
		},
		async saveData(){//保存数据
			if(!await this.$refs.infoForm.validate().catch(()=>0)) return this.$message({message: `数据校验失败，请检查`,type: 'error'});
				if(this.infoForm._id){//修改
					let _id = this.infoForm._id;delete this.infoForm._id;
					await this.loadFun(this.$db.collection(this.tableName).doc(_id).update(this.infoForm)).then(res=>console.log(res));
				}else{//新增
					await this.loadFun(await this.$db.collection(this.tableName).add(this.infoForm)).then(res=>console.log(res));
				}
			this.$message({type: 'success',message: '保存数据成功!'});
			this.infoFormShow = false;//关闭弹窗
			this.search()//刷新表格数据
		},
		async del(row){//删除数据
			await this.$confirm('确认要删除该条信息?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(async() => {
				await this.loadFun(this.$db.collection(this.tableName).doc(row._id).remove()).then(res=>console.log(res));
				this.$message({type: 'success',message: '删除成功!'});
				this.search()//刷新表格数据
			}).catch(() => {
				//取消逻辑
			});
		},
		test(){
			
		}
	}
}
</script>

<style>
	.search .el-form-item--mini.el-form-item {
		margin-bottom: 0;
	}
	.el-input.is-disabled .el-input__inner:disabled,.el-select .el-input.is-disabled .el-input__inner:disabled{
		color: #333;
		cursor: auto;
	}
	.el-input__inner:disabled+.el-input__suffix {
		display: none;
	}
</style>
