<template>
	<el-container>
	  <el-header class="search" style="height: 50px;padding: 10px;">
			<el-row>
				<el-col :span="18">
					<el-form :inline="true" size="mini" :model="param">
						<el-form-item label="姓名">
							<el-input v-model="param.name" placeholder="姓名"></el-input>
						</el-form-item>
						<el-form-item label="用户名">
							<el-input v-model="param.username" placeholder="用户名"></el-input>
						</el-form-item>
						<el-form-item label="角色">
							<el-select v-model="param.roles" multiple placeholder="角色">
								<el-option v-for="item in roleObj" :key="item._id" :label="item.roleName" :value="item._id"> </el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search">查询</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="6" style="text-align: right;">
					<el-button type="primary" icon="el-icon-circle-plus-outline" @click="showInfo({},true)" size="mini">新建用户</el-button>
					<el-dropdown style="margin-left: 3px;">
						<el-button size="mini"> 更多<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item @click.native="checkMentor()">导师账号检测</el-dropdown-item>
					    <!-- <el-dropdown-item @click.native="test()">导出</el-dropdown-item>
					    <el-dropdown-item @click.native="test()">其它</el-dropdown-item> -->
					  </el-dropdown-menu>
					</el-dropdown>
				</el-col>
			</el-row>
		</el-header>
	  <el-main style="padding: 0;height: calc(100vh - 100px);">
			<el-table :data="tableData" v-loading="tableLoad" size="mini" border height="100%" style="width: 100%">
				<el-table-column prop="name" label="姓名" width="100"> </el-table-column>
				<el-table-column prop="username" label="用户名" width="150"> </el-table-column>
				<el-table-column prop="roles" label="角色" >
					<template slot-scope="scope">
						<el-tag v-for="roleid in scope.row.roles" :key="roleid" style="margin-right: 5px;" size="mini">{{roleid.indexOf('administrator')>-1?'系统管理员':roleObj[roleid].roleName}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="org" label="导师组织" width="150"> </el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="180">
					<template slot-scope="scope">
						<el-button @click="showInfo(scope.row,false)" type="text" icon="el-icon-search" size="mini">查看</el-button>
						<el-button @click="showInfo(scope.row,true)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
						<el-button @click="del(scope.row)" type="text" style="color: #f56c6c;" icon="el-icon-delete" size="mini">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-main>
		<el-footer style="height: 50px;padding: 10px;text-align: right;">
			<el-pagination
				@size-change="(v)=>{page.size = v;this.search()}"
				@current-change="(v)=>{page.nowPage = v;this.search()}"
				:current-page="page.nowPage"
				:page-sizes="[20, 50, 100, 500]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total">
			</el-pagination>
		</el-footer>
		<el-dialog title="信息表单" :close-on-click-modal="false" :visible.sync="infoFormShow">
			<el-form ref="infoForm" :model="infoForm" size="mini" label-width="100px" :disabled="!isEdit">
				<el-form-item prop="name" label="姓名" :rules="[{ required: true, message: '该项必填'}]">
					<el-input v-model="infoForm.name"></el-input>
				</el-form-item>
				<el-form-item prop="username" label="账号" :rules="[{ required: true, message: '该项必填'}]">
					<el-input v-model="infoForm.username" :disabled="infoForm._id?true:false" ></el-input>
				</el-form-item>
				<el-form-item v-if="infoForm._id" prop="uuid" label="uuid">
					<el-input v-model="infoForm.uuid" disabled ></el-input>
				</el-form-item>
				<el-form-item v-if="!infoForm._id" prop="password" label="密码" :rules="[{ required: true, message: '该项必填'},{min: 8, max: 32, message: '长度在 8 到 32 个字符'},{pattern: /\D+/,message:'密码不能纯数字'},{pattern: /[^a-zA-Z]/,message:'密码不能纯字母'}]">
					<el-input v-model="infoForm.password" show-password ></el-input>
				</el-form-item>
				<el-form-item prop="roles" label="角色" :rules="[{ required: true, message: '该项必填'}]">
				 <el-select v-model="infoForm.roles" multiple placeholder="请选择">
						<el-option v-for="item in roleObj" :key="item._id" :label="item.roleName" :value="item._id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="infoForm.roles&&infoForm.roles[0]&&roleObj[infoForm.roles[0]].roleName == '导师'" prop="org" label="导师组织" :rules="[{ required: true, message: '该项必填'}]">
					<el-input v-model="infoForm.org"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="infoFormShow = false">{{isEdit?'取 消':'确 认'}}</el-button>
				<el-button v-if="isEdit" size="mini" type="primary" @click="saveData">提 交</el-button>
			</div>
		</el-dialog>
		<el-dialog title="导师账号信息校验" :visible.sync="mentorUsersShow" :close-on-click-modal="false" >
			<el-table :data="mentorUsers" size="mini" border height="50vh" style="width: 100%">
				<el-table-column prop="_id" label="导师"> </el-table-column>
				<el-table-column prop="" label="账号">
					<template slot-scope="scope">
						{{scope.row.scoreMentorObj?scope.row.scoreMentorObj.username:'无账号'}}
					</template>
				</el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="180">
					<template slot-scope="scope">
						<el-button v-if="!scope.row.scoreMentorObj&&scope.row._id&&!scope.row.save" @click="addMentorUser(scope.row)" type="text" icon="el-icon-circle-plus-outline" size="mini">创建账号</el-button>
						<el-button v-if="scope.row.scoreMentorObj&&!scope.row._id" @click="del(scope.row.scoreMentorObj)" type="text" style="color: #f56c6c;" icon="el-icon-delete" size="mini">删除账号</el-button>
						<span v-if="scope.row.save">已处理</span>
					</template>
				</el-table-column>
			</el-table>
		  <span slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="mentorUsersShow = false">确 定</el-button>
		  </span>
		</el-dialog>
	</el-container>
</template>

<script>
export default{
	name:'users',
	data() {
		return {
			tableName:'tcb-ext-cms-users',
			param: {name: '',region: '',roles:[]},
			tableData: [],
			page:{total:0,size:20,nowPage:1},//分页参数
			isEdit:false,
			infoFormShow:false,
			infoForm:{},
			tableLoad:false,//默认加载状态
			roleList:[],//角色对应关系
			mentorUsersShow:false,
			mentorUsers:[],//导师账号数据
		}
	},
	computed:{
		mentorRoleId(){//导师数据id
			for(let v of this.roleList){
				if(v.roleName == '导师') return v._id;
			}
			return '';
		},
		roleObj(){
			let obj = {};
			this.roleList.map(v=>{obj[v._id] = v});
			return obj;
		}
	},
	async created(){
		this.$db = this.$tcb.database();//写入数据库对象
		this.roleList = await this.$db.collection('tcb-ext-cms-user-roles').limit(100).get().then(res=>res.data);
		await this.search();
	},
	methods:{
		async loadFun(fun){//
			const loading = this.$loading();
			let res = await fun;
			loading.close();
			return res;
		},
		async search(page){//查询数据
			if(this.tableLoad == true) return;//不可以重复查询
			let param = {};//查询条件
			for (let key in this.param) {
				if(key == 'roles'){
					if(this.param[key].length>0)param[key] = this.$db.command.in(this.param[key]);
				}else if(this.param[key]) param[key] = this.param[key];
			}
			console.log(param);
			this.tableLoad = true;//表格加载中
			let colle = this.$db.collection(this.tableName).where(param);
			this.tableData = await colle.limit(this.page.size).skip(this.page.size*(this.page.nowPage-1)).get().then(res=>res.data);//查表逻辑
			this.page.total = await colle.count().then(res=>res.total);
			this.tableLoad = false;//表数据加载完毕
		},
		showInfo(row,isEdit){//查看编辑新增弹出窗
			this.isEdit = isEdit;//是否编辑态
			this.infoForm = JSON.parse(JSON.stringify(row));//写入数据
			this.infoFormShow = true;//显示弹窗
		},
		async saveData(){//保存数据
			if(!await this.$refs.infoForm.validate().catch(()=>0)) return this.$message({message: `数据校验失败，请检查`,type: 'error'});
				if(this.infoForm._id){//修改
					let _id = this.infoForm._id;delete this.infoForm._id;
					await this.loadFun(this.$db.collection(this.tableName).doc(_id).update(this.infoForm)).then(res=>console.log(res));
				}else{//新增
					//await this.loadFun(await this.$db.collection(this.tableName).add(this.infoForm)).then(res=>console.log(res));
					let {result} = await this.loadFun(this.$tcb.callFunction({name:'changeUsers',data:{type:'add',users:[this.infoForm]}}));
					if(result.code!='ok'){
						this.$message({type: 'error',message: result.msg||'系统错误!'});return;
					}
				}
			this.$message({type: 'success',message: '保存数据成功!'});
			this.infoFormShow = false;//关闭弹窗
			this.search()//刷新表格数据
		},
		async del(row){//删除数据
			await this.$confirm('确认要删除该条信息?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(async() => {
				//await this.loadFun(this.$db.collection(this.tableName).doc(row._id).remove()).then(res=>console.log(res));
				let {result} = await this.loadFun(this.$tcb.callFunction({name:'changeUsers',data:{type:'remove',users:[row]}}));
				if(result.code!='ok'){
					this.$message({type: 'error',message: result.msg||'系统错误!'});return;
				}
				this.$message({type: 'success',message: '删除成功!'});
				this.search()//刷新表格数据
			}).catch(() => {
				//取消逻辑
			});
		},
		async checkMentor(){//校验导师账号情况
			let {result} = await this.loadFun(this.$tcb.callFunction({name:'checkMentorUser'}));
			let userObj = {};
			result.users.map(v=>{userObj[v.username] = {data:v}});
			result.userInfo.map((v,i)=>{
				if(!v._id) delete result.userInfo[i];
				else {
					let username = v._id.split('(')[0];
					if(userObj[username]){
						v.scoreMentorObj = userObj[username].data;
						userObj[username].used = true;
					}
				}
			});
			for(let key in userObj){
				if(!userObj[key].used) result.userInfo.push({scoreMentorObj:userObj[key].data});
			}
			this.mentorUsers = result.userInfo;
			this.mentorUsersShow = true;
		},
		addMentorUser(row){
			let arr = row._id.replace(')','').split('(');
			this.showInfo({name:arr[1],username:arr[0],password:'a12345678',roles:[this.mentorRoleId]},true);
			this.$set(row,'save',true);
		}
	}
}
</script>

<style>
	.search .el-form-item--mini.el-form-item {
		margin-bottom: 0;
	}
	.el-input.is-disabled .el-input__inner:disabled,.el-select .el-input.is-disabled .el-input__inner:disabled{
		color: #333;
		cursor: auto;
	}
	.el-input__inner:disabled+.el-input__suffix {
		display: none;
	}
</style>
