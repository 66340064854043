<template>
  <div>
		<h-eard @setDate="date_=$event" :isMonth="true">
			<!-- <el-form-item>
				<el-select size="mini" v-model="selProduct" multiple collapse-tags placeholder="负责产品">
					<el-option v-for="v,key in products" :key="key" :label="key" :value="key"> </el-option>
				</el-select>
			</el-form-item> -->
			<!-- <el-form-item>
				<el-button size="mini" @click="selTable()">复制</el-button>
			</el-form-item> -->
			<el-form-item >
				<el-upload action="/" :show-file-list="false" :auto-upload="false" :on-change="importJb">
					<el-button size="mini" type="primary">导入安灯数据</el-button>
				</el-upload>
			</el-form-item>
		</h-eard>
		<div class="content">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="详情分析" name="tab1">
					<el-table :data="datas" size="mini" border style="width: 100%;" max-height="600" :default-sort = "{prop: '结单量', order: 'descending'}">
						<el-table-column type="index" width="50" label="序号"></el-table-column>
						<el-table-column prop="name" align="center" width="80" label="姓名"></el-table-column>
						<el-table-column prop="product" align="center" width="120" sortable label="负责产品"></el-table-column>
						<el-table-column v-for="col,i in cols" :key="i" :prop="col" sortable align="center" :label="col">
							<template slot-scope="scope">
								{{/.*[率|比|度].*/.test(col)?(scope.row[col]*100).toFixed(2)+'%':Math.round(scope.row[col] * 100)/100}}
							</template>
						</el-table-column>
					</el-table>
					<!-- <div  class="el-table el-table--fit el-table--border el-table--scrollable-y el-table--enable-row-transition el-table--mini" >
						<table ref="table1" cellspacing="0" cellpadding="0" border="0" class="el-table__header">
							<thead class="is-group">
								<tr class="">
									<th class="is-center" > <div class="cell" >序号</div> </th>
									<th class="is-center" > <div class="cell" >负责产品</div> </th>
									<th class="is-center" > <div class="cell" >姓名</div> </th>
									<th v-for="col,index in cols" :key="index" class=" is-center is-leaf">
										<div class="cell">{{col}}</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="d,index in datas" class="el-table__row" :key="index">
									<td class="is-center"> <div class="cell" > {{index++}} </div> </td>
									<td class="is-center"> <div class="cell" > {{d.product||''}} </div> </td>
									<td class="is-center"> <div class="cell" > {{d.name}} </div> </td>
									<td v-for="col,index in cols" class="is-center" :key="index" >
										<div class="cell" >
											{{/.*[率|比|度].*/.test(col)?(d[col]*100).toFixed(2)+'%':Math.round(d[col] * 100)/100}}
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div> -->
				</el-tab-pane>
				<el-tab-pane label="图表分析" name="tab2">
					<div>
						<el-row>
							<el-col :span="24"><div ref="echats1" style="height: 600px;"></div></el-col>
						</el-row>
						<el-row>
							<el-col :span="24"><div ref="echats2" style="height: 500px;"></div></el-col>
						</el-row>
					</div>
					<el-row>
						<el-col :span="12"><div ref="echats3" style="height: 500px; width: 100%;"></div></el-col>
						<el-col :span="12"><div ref="echats4" style="height: 500px; width: 100%;"></div></el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="工单TOP榜" name="tab3">
					<el-row :gutter="20">
						<el-col v-for="(item,index) in topCols" :key="index" :span="8">
							<el-card class="box-card" style="margin-bottom: 20px;">
								<div slot="header" class="clearfix">
								    <span style="font-weight: 800;">{{item}}</span>
								    <label style="float: right; color: #888;">工单量前50%数据</label>
								  </div>
								<div v-for="user,i in getDataByTitle(item)" :key="i" class="top-card">
									<p><span class="ranking">{{i+1}}</span><span class="name">{{user.name}}</span><span class="value">{{/.*[率|比|度].*/.test(item)?(user[item]*100).toFixed(2)+'%':Math.round(user[item] * 100)/100}}</span></p>
								</div>
							</el-card>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="队列数据" name="tab4">
					<el-row :gutter="20">
						<el-col :span="24">
							<el-card class="box-card" style="margin-bottom: 20px;">
								<div slot="header" class="clearfix">
									<span style="font-weight: 800;">{{selProduct}}队列数据分析</span>
									<label style="float: right;">
										<el-select size="mini" v-model="selProduct" placeholder="负责产品">
											<template v-for="v,key in products" >
												<el-option :key="key" v-if="key!='其它'" :label="key" :value="key"> </el-option>
											</template>
											<el-option label="其它产品" value="其它"> </el-option>
										</el-select>
									</label>
								</div>
								<el-row :gutter="20" class="top-card">
									<el-col :span="8">
										<el-card shadow="never">
											<div ref="echatsp1" style="height: 400px;"></div>
										</el-card>
									</el-col>
									<el-col :span="8">
										<el-card shadow="never">
											<div ref="echatsp2" style="height: 400px;"></div>
										</el-card>
									</el-col>
									<el-col :span="8">
										<el-card shadow="never">
											<div ref="echatsp3" style="height: 400px;"></div>
										</el-card>
									</el-col>
								</el-row>
							</el-card>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="员工分析" name="tab5">
					开发中...
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog title="安灯数据导入确认" :visible.sync="showAndonData">
			<el-form size="mini" label-width="100px" style="max-height: 300px;overflow: auto;">
				<el-form-item label="数据导入月份">
					<el-date-picker type="month" placeholder="选择月份" value-format="yyyy-MM" v-model="saveMonth" style="width: 100%;"></el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="showAndonData = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="updateAndonData(andonData);showAndonData = false">提 交</el-button>
			</div>
		</el-dialog>
  </div>
</template>

<script>
import Heard from '../components/heard.vue'
import XLSX from 'xlsx'
import * as echarts from 'echarts'
export default {
	name: 'count',
	components:{'h-eard':Heard},
	data(){
		return {
			activeName:'tab1',
			cols:["服务差评率","反馈率","服务态度差占比","技术能力弱占比","解决时效差占比","工单12h闭环率","80分位工单总时长(h)","结单量","满意度(汇总)","满意度(MC)","满意度(KA)","工单总时长(h)","90分位总对外回复次数","专项处理时长(MC)","专项处理时长(KA)","专项处理时长(汇总)","透传量","透传率","透传拒绝率"],
			topCols:["服务差评率","反馈率","工单12h闭环率","80分位工单总时长(h)","结单量","满意度(汇总)","工单总时长(h)","90分位总对外回复次数","专项处理时长(汇总)"],
			date_:[],//查询日期范围
			spms:[],
			selSpm:'',
			userDatas:[],//已有用户数据
			dbDatas:[],
			andonDatas:{},
			andonData:[],//安灯导入数据
			showAndonData:false,
			saveMonth:'',
			//products:{},
			selProduct:'',
			echatsObj:{},//echats
			echatsObj2:{},//echats
		}
	},
	async created() {
		
		let load = this.$loading();
		
		this.$db = this.$tcb.database();
		
		let user = await this.$db.collection('user-andon-data').limit(1000).get();
		this.dbDatas = user.data;
		//user.data.map(v=>{this.products[v.product||'其它'] = true;});
		
		load.close();
	},
	computed:{
		ym_ym:{
			get(){
				return this._date&&this._date[0]?[this._date[0].substr(0,7),this._date[1].substr(0,7)]:[];
			},
			set(v){
				this._date = [v[0]+'01',v[1]+'01'];
			}
		},
		sDateList(){//实时计算的查询月份数组
			let date_ = this.date_, list = [];
			if(!date_) return [];
			if(date_[0] && date_[1]){
				let nowDate = new Date(date_[0]);
				while(nowDate <= date_[1]){//对日期进行循环
					let ym = nowDate.format('YYYY-MM');
					if(list[list.length-1] != ym) list.push(ym);
					nowDate.setDate(nowDate.getDate()+1);//加一天
				}
			}
			return list;
		},
		dateAndPro(){//用于实现watch队列数据
			return {datas:this.datas,selProduct:this.selProduct};
		},
		datas(){//实时计算的数据
			let list = [];
			for(let index in this.dbDatas) {
				let user = this.dbDatas[index];
				let obj = {
					_id:user._id,
					name:user.name,
					pName:user.pName,
					phone:user.phone,
					product:user.product,
				};
				for (let ym of this.sDateList) {
					if(!user.andonData) break;
					let val = user.andonData[ym];
					if(val){//存在值才进行写值和判断
						for (let s of this.cols) {
							if(obj[s]){
								obj[s] += val[s]||0;
							}else{
								obj[s] = val[s]||0;
							}
						}
					}
				}
				for (let s of this.cols) {
					if(obj[s]){
						if(s.indexOf('量') == -1){
							obj[s] = obj[s]/this.sDateList.length;
						}
					}
				}
				if(obj['结单量'])list.push(obj);
			}
			// list.sort((a,b)=>{
			// 	return b['结单量'] - a['结单量']
			// });
			//this.echatsData();
			return list;
		},
		products(){//有数据的产品
			let obj = {};
			this.datas.map(v=>{obj[v.product||'其它'] = true;});
			return obj;
		},
		pNameObj(){//p账号对应的账号id
			let obj = {};
			this.dbDatas.map(user=>{
				obj[user.pName] = user._id;
			});
			return obj;
		},
		pNameObjUser(){
			let obj = {};
			this.userDatas.map(user=>{
				obj[user.pName] = user;
			});
			return obj;
		}
	},
	watch: {
		sDateList(){
			this.echatsData();//
		},
		dateAndPro(){
			this.echatsData2();//
		}
	},
	methods: {
		getDataByTitle(title){
			let _data = this.datas.sort((a,b)=>{return (b['结单量'] - a['结单量'])});
			let data = _data.slice(0,parseInt(_data.length/2)+1);
			let type = /.*[差|弱|时长|透传|次数].*/.test(title)?-1:1;
			data.sort((a,b)=>{
				return (b[title] - a[title])*type
			});
			return data.slice(0,5);
		},
		handleClick(tab, event) {
			if(tab.name == 'tab2'){//需要加载图表
				this.$nextTick(()=>{
					for (let mycharts in this.echatsObj) {
						this.echatsObj[mycharts].resize();
					}
				})
			}else if(tab.name == 'tab4'){//需要加载图表
				this.$nextTick(()=>{
					for (let mycharts in this.echatsObj2) {
						this.echatsObj2[mycharts].resize();
					}
				})
			}
		},
		async importJb(file){
			let _this = this;
			this.userDatas = await this.$db.collection('cms-gyy-user').limit(1000).get().then(res=>res.data);
			readWorkbookFromLocalFile(file.raw,async function(e){
				let andonDatas = XLSX.utils.sheet_to_json(e.Sheets[e.SheetNames[0]]);
				let saveData = [];
				for(let obj of andonDatas){
					let _id = _this.pNameObj[obj['接单人']];//已有数据对照
					let user = _this.pNameObjUser[obj['接单人']];//user表数据对照id
					if(_id){
						saveData.push({type:'update',_id:_id,data:obj});
					}else if(user){
						saveData.push({type:'add',data:obj,user:{name:user.name,pName:user.pName,phone:user.phone,product:user.product}});
					}
				}
				_this.andonData = saveData;
				_this.showAndonData = true;
			})
			function readWorkbookFromLocalFile(file, callback) {
				var reader = new FileReader();
				reader.onload = function(e) {
					var data = e.target.result;
					var workbook = XLSX.read(data, {type: 'binary'});
					if(callback) callback(workbook);
				};
				reader.readAsBinaryString(file);
			}
		},
		async updateAndonData(){//导入安灯数据
			//console.log(this.andonData,this.saveMonth,{andonData:{[this.saveMonth]:this.andonData}});
			let load = this.$loading(),promiseArr = [];
			for(let d of this.andonData){
				//console.log(d._id,d);
				let pro = d.type=='update'?this.$db.collection('user-andon-data').doc(d._id).update({andonData:{[this.saveMonth]:d.data}})
				:this.$db.collection('user-andon-data').add({...d.user,andonData:{[this.saveMonth]:d.data}})
				promiseArr.push(pro);
			}
			let res = await Promise.all(promiseArr);//等待全部处理成功
			console.log(res);
			let user = await this.$db.collection('user-andon-data').limit(1000).get();
			this.dbDatas = user.data;
			load.close();
			this.$message({
				message: '数据导入成功',
				type: 'success',
			});
		},
		selTable() {//选中table复制
			var selection = window.getSelection();
			var range = document.createRange();
			range.selectNodeContents(this.$refs.table1);
			selection.removeAllRanges();
			selection.addRange(range);
		},
		getTableEchatsData(title,cpList,data,type){
			console.log(data);
			let dimensions = ['ym'], source = [], series = [];
			for (let cp in cpList){
				//dimensions.push(cp);//写入产品
				series.push({
					type: type||'bar',
					areaStyle: {},
					stack: 'total',
					//stack: type?'':'total',
					label: {show: true},
					emphasis: {focus: 'series'},
					//markPoint: type?{data: [{type: 'max', name: '最大值'},{type: 'min', name: '最小值'}]}:{},
				})
			}
			this.getSeries(cpList,data).map(obj=>{dimensions.push(obj.name);});
			
			for (let key in data) {source.push(data[key]);}
			return {
				title:{text:title},
				tooltip: {trigger: 'axis',axisPointer: {type: 'shadow'},formatter:function(a) {
					let res = '', sum = 0;
					a.forEach((item,index) => {
						if(index == 0) {
							res += `${item.axisValue}<br/>`;
							res += `合计 : {{合计}}<br/>`;
						}
						sum += item.value[item.seriesName]||0;
						res += `${item.marker} ${item.seriesName} : ${item.value[item.seriesName]||0}<br/>`;
					})
					return res.replace('{{合计}}',sum);
				}},
				legend: {},//data: legend
				dataset:{dimensions,source},
				grid: {left: '3%',right: '4%',bottom: '3%',containLabel: true},
				xAxis: {type: 'category'},
				yAxis: {type: 'value'},
				series:series
			};
		},
		getPicEchatsData(title,cpList,name,data){
			let _series = this.getSeries(cpList,data);
			return {
				title: {text: title,left: 'center'},
				tooltip: {trigger: 'item',formatter: '{a} <br/>{b} : {c} ({d}%)'},
				legend: {orient: 'vertical',left: 'left',},
				series: [{
					name: name,
					type: 'pie',
					radius: '50%',
					data: _series,
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}]
			}
		},
		getSeries(cpList,data){//获取扇形图统计数据（可排序，所以柱状图也能用到）
			let _series = [];
			for (let cp in cpList){
				let obj = {name:cp,value:0};
				for(let key in data){
					obj.value += data[key][cp]||0;
				}
				_series.push(obj);
			}
			_series.sort((a,b)=>{
				return b.name=='其它'?-1:b.value - a.value;
			});
			return _series;
		},
		echatsData(){
			if(!this.$refs.echats1) return;
			let cpObj = {},//产品数据
				ppObj = {},//人员数据
				cpList = {};//产品列表
			for (let ym of this.sDateList) {//循环月份
				if(!cpObj[ym]) cpObj[ym] = {ym:ym};
				if(!ppObj[ym]) ppObj[ym] = {ym:ym};
				for(let user of this.dbDatas){//循环数据
					if(!user.andonData) continue;
					let product = user.product||'其它';
					let val = user.andonData[ym];
					if(val && val['结单量']){
						cpObj[ym][product] = cpObj[ym][product]?(cpObj[ym][product]+val['结单量']):val['结单量'];
						ppObj[ym][product] = ppObj[ym][product]?(ppObj[ym][product]+1):1;
						cpList[product] = true;
					}
				}
			}
			
			this.echatsObj.echats1 = echarts.init(this.$refs.echats1);
			this.echatsObj.echats1.setOption(this.getTableEchatsData('产品工单量分布图',cpList,cpObj));
			
			this.echatsObj.echats2 = echarts.init(this.$refs.echats2);
			this.echatsObj.echats2.setOption(this.getTableEchatsData('产品人数折线图',cpList,ppObj,'line'));
			
			this.echatsObj.echats3 = echarts.init(this.$refs.echats3);
			this.echatsObj.echats3.setOption(this.getPicEchatsData('产品工单分布图(总)',cpList,'工单量',cpObj));
			
			this.echatsObj.echats4 = echarts.init(this.$refs.echats4);
			this.echatsObj.echats4.setOption(this.getPicEchatsData('处理人数分布图(总)',cpList,'处理人',ppObj));
		},
		echatsData2(){
			if(!this.$refs.echatsp1) return;
			let ppjdlObj = {},//人员结单量数据
				ppyxdObj = {},//人员评价单数据
				ppmydObj = {},//人员处理时长
				ppList = {};//人员列表
				//cpList = {'满意度'};//
			for(let user of this.datas){//循环数据
				if((user.product||'其它') != this.selProduct) continue;
				ppList[user.name] = true;
				ppjdlObj[user.name] = user['结单量'];
				ppyxdObj[user.name] = Math.round(user['结单量']*user['反馈率']);
				ppmydObj[user.name] = Math.round(user['专项处理时长(汇总)']);
			}
			this.echatsObj2.echatsp1 = echarts.init(this.$refs.echatsp1);
			this.echatsObj2.echatsp1.setOption(this.getPicEchatsData('工单总量分布',ppList,'工单量',{data:ppjdlObj}));
			
			this.echatsObj2.echatsp2 = echarts.init(this.$refs.echatsp2);
			this.echatsObj2.echatsp2.setOption(this.getPicEchatsData('评价单量分布',ppList,'评价单量',{data:ppyxdObj}));
			
			this.echatsObj2.echatsp3 = echarts.init(this.$refs.echatsp3);
			this.echatsObj2.echatsp3.setOption(this.getPicEchatsData('处理时长占比',ppList,'处理时长',{data:ppmydObj}));
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	td.pb-val:hover {cursor: pointer;background: #03A9F4!important;color: #fff;}
	.pb-list .el-radio {margin-right: 20px;margin-bottom: 20px;margin-left: 0!important;width: 80px;}
	.content{padding: 40px 10px 10px;}
	.dialog-pb .el-dialog__body {padding: 5px 20px;padding-bottom: 0;}
	.dialog-pb .el-dialog__footer {padding-top: 0;padding-bottom: 10px;}
	.dialog-pb .el-divider--horizontal{margin: 5px 0;}
	.el-table th{user-select: auto;}
	.sles-div{display: inline-flex;}
	.sles-div .el-radio{margin-right: 15px;}
	td{background: #fff;}
	.el-form-item{margin-bottom: 0;}
	.top-card .ranking {display: inline-block;background: #ff9800;border-radius: 50%;text-align: center;width: 22px;margin-right: 10px;}
	.top-card .value {float: right;}
</style>
