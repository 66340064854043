<template>
	<el-container>
	  <el-header class="search" style="height: 50px;padding: 10px;">
			<el-row>
				<el-col :span="18">
					<el-form :inline="true" size="mini" :model="param">
						<el-form-item label="姓名">
							<el-input style="width: 80px;" v-model="param.name" placeholder="姓名"></el-input>
						</el-form-item>
						<el-form-item label="工号">
							<el-input style="width: 80px;" v-model="param.userNo" placeholder="工号"></el-input>
						</el-form-item>
						<el-form-item label="在职状态">
							<el-select style="width: 80px;" v-model="param.isJob" placeholder="请选择" clearable >
								<el-option label="在职" :value="true"></el-option>
								<el-option label="离职" :value="false"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="SPM">
							<el-select style="width: 140px;" v-model="param.spm" placeholder="SPM" multiple collapse-tags clearable >
								<el-option v-for="v in selectData.spms" :key="v._id" :label="v.name" :value="v.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="办公点">
							<el-select v-model="param.workLocation" placeholder="办公地点" multiple collapse-tags clearable >
								<el-option v-for="v in selectData.workLocation" :key="v" :label="v" :value="v"></el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="导师/绩效导师">
							<el-select v-model="param.mentor" placeholder="导师" multiple collapse-tags clearable >
								<el-option v-for="v,key in mentorsObj" :key="v._id" :label="key" :value="key"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item>
							<el-button type="primary" @click="search">查询</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="6" style="text-align: right;">
					<el-button type="primary" icon="el-icon-circle-plus-outline" @click="showInfo({isGrade:true,isJob:true},true)" size="mini">新增员工</el-button>
					<el-dropdown style="margin-left: 3px;">
						<el-button size="mini"> 更多<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
						<el-dropdown-menu slot="dropdown">
							<!-- <el-dropdown-item @click.native="test()">导入</el-dropdown-item> -->
							<el-dropdown-item @click.native="exportXlsx()">导出</el-dropdown-item>
							<!-- <el-dropdown-item @click.native="test()">其它</el-dropdown-item> -->
						</el-dropdown-menu>
					</el-dropdown>
				</el-col>
			</el-row>
		</el-header>
	  <el-main style="padding: 0;height: calc(100vh - 100px);">
			<el-table id="table1" :data="tableData" v-loading="tableLoad" size="mini" border height="100%" style="width: 100%">
				<el-table-column prop="spm" label="SPM" align="center" width="80" > </el-table-column>
				<el-table-column prop="name" label="姓名" align="center" width="80" > </el-table-column>
				<el-table-column prop="isJob" align="center" width="120" label="入职时间"> </el-table-column>
				<el-table-column prop="isJob2" align="center" width="80" label="在职状态">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.isJob" size="small" type="success" >在职</el-tag>
						<el-tooltip v-else-if="isAdmin" effect="dark" :content="scope.row.outDate+'离职，点击删除'" placement="top">
							<el-button size="mini" @click="deleteUser(scope.row._id)">删除</el-button>
						</el-tooltip>
						<el-tag v-else size="small" type="info">已离职</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="userNo" label="工号" align="center" width="80" > </el-table-column>
				<el-table-column prop="phone" label="电话" align="center" width="100" > </el-table-column>
				<el-table-column prop="pName" label="p账号" align="center" width="100" > </el-table-column>
				<el-table-column prop="department" label="悦智架构" align="center" width="150" > </el-table-column>
				<el-table-column prop="queue" label="队列" align="center" width="120" > </el-table-column>
				<el-table-column prop="product" label="负责产品" align="center" width="120" > </el-table-column>
				<el-table-column prop="workLocation" label="办公点" align="center" width="110"> </el-table-column>
				<el-table-column prop="cert" label="持有证书" align="center" width="160">
					<template slot-scope="scope">
						<el-tag v-for="cert in scope.row.cert" :key="cert" size="mini" style="margin-right: 3px;">{{cert}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="mentor" label="导师" align="center" width="150"> </el-table-column>
				<el-table-column prop="mentorOrg" label="导师组织" align="center" width="150"> </el-table-column>
				<el-table-column prop="scoreMentor" label="绩效导师" align="center" width="150"> </el-table-column>
				<el-table-column prop="workWx" label="微信" align="center" width="120"> </el-table-column>
				<el-table-column v-if="!isExport" fixed="right" align="center" label="操作" width="150">
					<template slot-scope="scope">
						<el-button @click="showInfo(scope.row,false)" type="text" icon="el-icon-search" size="mini">查看</el-button>
						<el-button @click="showInfo(scope.row,true)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
						<!-- <el-button @click="del(scope.row)" type="text" style="color: #f56c6c;" icon="el-icon-delete" size="mini">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</el-main>
		<el-footer style="height: 50px;padding: 10px;text-align: right;">
			<el-pagination
				@size-change="(v)=>{page.size = v;this.search()}"
				@current-change="(v)=>{page.nowPage = v;this.search()}"
				:current-page="page.nowPage"
				:page-sizes="[200, 500, 1000]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total">
			</el-pagination>
		</el-footer>
		<el-dialog title="信息表单" :close-on-click-modal="false" :visible.sync="infoFormShow">
			<el-form ref="infoForm" :model="infoForm" size="mini" label-width="100px" :disabled="!isEdit">
				<el-row>
					<el-col :span="8">
						<el-form-item prop="name" label="姓名" :rules="[{ required: true, message: '该项必填'}]">
							<el-input v-model="infoForm.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="phone" label="手机号" :rules="[{ required: true, message: '该项必填'}]">
							<el-input v-model="infoForm.phone"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="spm" label="组长" :rules="[{ required: true, message: '该项必填'}]">
							<el-select style="width: 100%;" v-model="infoForm.spm" placeholder="SPM">
								<el-option label="特殊(不归属组)" value="特殊"></el-option>
								<el-option v-for="v in selectData.spms" :key="v._id" :label="v.name" :value="v.name"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="工号">
							<el-input v-model="infoForm.userNo" placeholder="工号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="pName" label="p账号" :rules="[{ required: infoForm.isAdon, message: '该项必填'}]">
							<el-input v-model="infoForm.pName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="安灯排班">
							<el-date-picker type="date" placeholder="开始日期(无排班留空)" value-format="yyyy-MM-dd" v-model="infoForm.isAdon" style="width: 100%;"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item prop="post" label="岗位" :rules="[{ required: true, message: '该项必填'}]">
							<el-input v-model="infoForm.post"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="workLocation" label="办公点" :rules="[{ required: true, message: '该项必填'}]">
							<el-select style="width: 100%;" v-model="infoForm.workLocation" placeholder="办公点" filterable allow-create>
								<el-option v-for="v in selectData.workLocation" :key="v" :label="v" :value="v"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="办公邮箱">
							<el-input v-model="infoForm.workEmail" placeholder="办公邮箱"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item prop="department" label="悦智架构" :rules="[{ required: true, message: '该项必填'}]">
							<el-select style="width: 100%;" v-model="infoForm.department" placeholder="架构" filterable allow-create>
								<el-option v-for="v in selectData.departments" :key="v" :label="v" :value="v"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="queue" label="队列" :rules="[{ required: true, message: '该项必填'}]">
							<el-input v-model="infoForm.queue"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="product" label="负责产品" :rules="[{ required: true, message: '该项必填'}]">
							<el-select style="width: 100%;" v-model="infoForm.product" placeholder="产品" filterable allow-create>
								<el-option v-for="v in selectData.cpList" :key="v" :label="v" :value="v"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item prop="mentor" label="导师" >
							<el-select style="width: 100%;" v-model="infoForm.mentor" placeholder="导师" clearable >
								<el-option v-for="v,key in mentorsObj" :key="v._id" :label="key" :value="key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="mentorOrg" label="导师组织" >
							<el-input v-model="infoForm.mentorOrg" disabled style="color: #606266;"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="scoreMentor" label="绩效导师" >
							<el-select style="width: 100%;"  v-model="infoForm.scoreMentor" placeholder="导师" clearable >
								<el-option v-for="v,key in mentorsObj" :key="v._id" :label="key" :value="key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item prop="cert" label="持有证书" >
							<el-select style="width: 100%;" v-model="infoForm.cert" placeholder="无" multiple filterable allow-create>
								<el-option v-for="v in selectData.certs" :key="v" :label="v" :value="v"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item prop="workWx" label="微信" >
							<el-input v-model="infoForm.workWx"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="infoForm._id">
						<el-form-item prop="isJob" label="在职状态" :rules="[{ required: true, message: '该项必填'}]">
							<!-- <el-select style="width: 100%;" v-model="infoForm.isJob" placeholder="请选择" >
								<el-option label="在职" :value="true"></el-option>
								<el-option label="离职" :value="false"></el-option>
							</el-select> -->
							<el-radio v-model="infoForm.isJob" :label="true">在职</el-radio>
							<el-radio v-model="infoForm.isJob" :label="false">离职/离职中</el-radio>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="8">
						<el-form-item label="入职时间" :rules="[{ required: true, message: '该项必填'}]">
							<el-date-picker type="date" placeholder="开始日期(无排班留空)" value-format="yyyy-MM-dd" v-model="infoForm.joinDate" style="width: 100%;"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="infoForm.isJob == false">
						<el-form-item label="离职时间" :rules="[{ required: true, message: '该项必填'}]">
							<el-date-picker type="date" placeholder="开始日期(无排班留空)" value-format="yyyy-MM-dd" v-model="infoForm.outDate" style="width: 100%;"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-form-item prop="isGrade" label="绩效考核" :rules="[{ required: true, message: '该项必填'}]">
						<el-radio v-model="infoForm.isGrade" :label="true">参与</el-radio>
						<el-radio v-model="infoForm.isGrade" :label="false">不参与</el-radio>
					</el-form-item>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="infoFormShow = false">{{isEdit?'取 消':'确 认'}}</el-button>
				<el-button v-if="isEdit" size="mini" type="primary" @click="saveData">提 交</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>

<script>
import XLSX from "xlsx";
import FileSaver from "file-saver";
export default{
	name:'userList',
	data() {
		return {
			tableName:'cms-gyy-user',
			param: {name: '',spm: [],workLocation:'',mentor:'',isJob:true},
			selectData:{
				spms:[],//spm列表
				workLocation:['松日4F','第三极大厦11F','国人通信大厦6F','北京长远天地7F','松日16F','松日14F','武汉'],//办公地点列表
				posts:['运维工程师','开发支持工程师','部门助理','前端开发工程师','数据分析师'],//岗位列表
				departments:["计算与安全组","网络组","通信与应用组","数据支持组","音视频云端组","音视频终端组","产研一组","产研二组"],//部门
				mentors:[],//导师列表，在线获取
				cpList:['音视频-终端','音视频-云端','音视频-云通','即时通信IM','云服务器','数据库','大数据AI','中间件SCF','直播','加速','安全','计费','网络','计算','存储','容器TKE','其他'],//负责产品列表
				certs:['TCA运维','TCP运维','TCA架构','TCP架构']
			},
			tableData: [],
			page:{total:0,size:200,nowPage:1},//分页参数
			isEdit:false,
			infoFormShow:false,
			infoForm:{},
			tableLoad:false,//默认加载状态
			isExport:false,//是否导出状态
		}
	},
	computed:{
		mentorsObj(){
			let obj = {};
			for (let s of this.selectData.mentors) {obj[`${s.name}(${s.username})`] = s;}
			return obj;
		}
	},
	async created(){
		this.$db = this.$tcb.database();//写入数据库对象
		this.selectData.spms = await this.$tcb.callFunction({name:'getUsers',data:{roles:['SPM']}}).then(res=>res.result.data);
		this.selectData.mentors = await this.$tcb.callFunction({name:'getUsers',data:{roles:['导师']}}).then(res=>res.result.data);
		await this.search();
	},
	watch:{
		'infoForm.mentor':function(val,old){
			if(val && this.mentorsObj[val]) this.infoForm.mentorOrg = this.mentorsObj[val].org;
		}
	},
	methods:{
		async loadFun(fun){//
			const loading = this.$loading();
			let res = await fun;
			loading.close();
			return res;
		},
		async search(page){//查询数据
			if(this.tableLoad == true) return;//不可以重复查询
			let param = {};//查询条件
			const _ = this.$db.command;
			for (let key in this.param) {
				if(this.param[key] || this.param[key] === false){
					if(this.param[key].push){
						if(this.param[key].length>0) param[key] = _.in(this.param[key]);
					}else{
						param[key] = this.param[key]
					}
				}
			}
			this.tableLoad = true;//表格加载中
			let colle = this.$db.collection(this.tableName).where(param);
			this.tableData = await colle.field({pbData:0,performance:0}).limit(this.page.size).skip(this.page.size*(this.page.nowPage-1)).get().then(res=>res.data);//查表逻辑
			this.page.total = await colle.count().then(res=>res.total);
			this.tableLoad = false;//表数据加载完毕
		},
		showInfo(row,isEdit){//查看编辑新增弹出窗
			this.isEdit = isEdit;//是否编辑态
			this.infoForm = JSON.parse(JSON.stringify(row));//写入数据
			this.infoFormShow = true;//显示弹窗
		},
		async saveData(){//保存数据
			if(!await this.$refs.infoForm.validate().catch(()=>0)) return this.$message({message: `数据校验失败，请检查`,type: 'error'});
				if(this.infoForm._id){//修改
					let _id = this.infoForm._id;delete this.infoForm._id;delete this.infoForm._openid;
					await this.loadFun(this.$db.collection(this.tableName).doc(_id).update(this.infoForm)).then(res=>console.log(res));
				}else{//新增
					let infoForm = {...this.infoForm,pbData:{[this.infoForm.joinDate]:'早'},visaFree:{[this.infoForm.joinDate]:true}};
					await this.loadFun(await this.$db.collection(this.tableName).add(infoForm)).then(res=>console.log(res));
				}
			this.$message({type: 'success',message: '保存数据成功!'});
			this.infoFormShow = false;//关闭弹窗
			this.search()//刷新表格数据
		},
		async del(row){//删除数据
			await this.$confirm('确认要删除该条信息?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(async() => {
				await this.loadFun(this.$db.collection(this.tableName).doc(row._id).remove()).then(res=>console.log(res));
				this.$message({type: 'success',message: '删除成功!'});
				this.search()//刷新表格数据
			}).catch(() => {
				//取消逻辑
			});
		},
		exportXlsx(){
			//console.log(this.$refs.table1.$el);
			this.isExport = true;
			//const loading = this.$loading();
			this.$nextTick(()=>{
				let wb = XLSX.utils.table_to_book(document.querySelector('#table1'));
				/* 获取二进制字符串作为输出 */
				var wbout = XLSX.write(wb, {bookType: "xlsx",bookSST: true,type: "array"});
				try {
						FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }),"员工信息.xlsx");
				} catch (e) {
					console.log(e, wbout);
				}
				this.isExport = false;
				//loading.close();
			})
		}
	}
}
</script>

<style>
	.search .el-form-item--mini.el-form-item {
		margin-bottom: 0;
	}
	.el-input.is-disabled .el-input__inner:disabled,.el-select .el-input.is-disabled .el-input__inner:disabled{
		color: #333;
		cursor: auto;
	}
	.el-input__inner:disabled+.el-input__suffix {
		display: none;
	}
</style>
